import { useEffect, useState } from 'react';
import { EVENT } from 'Services/Eventing';

import Theme from '@uilib/business-components/_utils/theme';
import ConfirmationModal from 'uilib-wrappers/confirmation-modal';
import Tooltip from 'uilib-wrappers/tooltip';
import Button from 'uilib-wrappers/button';
import i18n from 'Services/i18n';
import { Navigator } from 'Services';

export default function SubscribeConfirmationModal(props) {
    const [show, setShow] = useState(false);
    const [content, setContent] = useState({
        type: props.defaultType || 'info',
        message: '',
    });

    function handleClose(event, withDefaultRedirection) {
        setShow(false);

        if (typeof content?.callback === 'function') {
            content.callback();
        } else if (withDefaultRedirection && content?.redirectToDefaultState) {
            Navigator.go(event, props.defaultRedirection);
        }
    }

    useEffect(() => {
        if (typeof props.onShowChange === 'function') {
            props.onShowChange(show);
        }
    }, [show]);

    useEffect(() => {
        const eventUnsubscribe = EVENT.subscribe(props.eventName, (event, content) => {
            setContent(content);
            setShow(true);
        });

        return eventUnsubscribe;
    }, []);

    return (
        content && (
            <Theme.Provider theme={{ ConfirmationModal: content.theme || {} }}>
                <ConfirmationModal
                    id={props.id}
                    show={show}
                    type={content.type}
                    className={content.className}
                    message={content.message}
                    text={content.details}
                    children={content.children}
                    onDispose={handleClose}
                    buttons={
                        content.buttons?.map((buttonProps) => {
                            const { id, type, onClick, tooltipContent, tooltipDisabled = true } = buttonProps;
                            return (
                                <Tooltip
                                    tooltipContent={i18n(tooltipContent)}
                                    disabled={tooltipDisabled}
                                    id={`${props.id}-button-${id}-tooltip`}
                                >
                                    <div>
                                        <Button
                                            {...buttonProps}
                                            id={`${props.id}-button-${id}`}
                                            type={type || 'secondary'}
                                            onClick={(event) => {
                                                handleClose(event);
                                                onClick?.(event);
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            );
                        }) || [
                            <Button
                                id={`${props.id}-button-ok`}
                                type="primary"
                                text="OK"
                                onClick={(event) => handleClose(event, true)}
                            />,
                        ]
                    }
                />
            </Theme.Provider>
        )
    );
}
