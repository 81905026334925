import IconObjectStethoscope from '@uilib/assets-business-icons/IconObjectStethoscope';
import IconNetworkIncidents from '@uilib/assets-business-icons/IconNetworkIncidents';
import IconStatusError from '@uilib/assets-business-icons/IconStatusError';
import IconTerminalExecutable from '@uilib/assets-business-icons/IconTerminalExecutable';
import IconHardwareDevices from '@uilib/assets-business-icons/IconHardwareDevices';
import IconLetterI from '@uilib/assets-business-icons/IconLetterI';
import IconDataChartCombo from '@uilib/assets-business-icons/IconDataChartCombo';
import IconMediaAlarm from '@uilib/assets-business-icons/IconMediaAlarm';
import IconDataList from '@uilib/assets-business-icons/IconDataList';
import IconEngineeringSettings from '@uilib/assets-business-icons/IconEngineeringSettings';
import IconMediaPlay from '@uilib/assets-business-icons/IconMediaPlay';
import IconHardwareStatistics from '@uilib/assets-business-icons/IconHardwareStatistics';
import IconTerminalScripts from '@uilib/assets-business-icons/IconTerminalScripts';
import IconNetworkAd from '@uilib/assets-business-icons/IconNetworkAd';

import { lazy } from 'react';

//-----------------------------------------------------------------------------
// Lazy Loader with logging.
//
// https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
//-----------------------------------------------------------------------------
const RETRY_TIMEOUT = 1000;
const RETRY_ATTEMPTS = 3;

// TODO: Update lazyLoader usage with import operator instead of import statement when ES2020 is fully supported.
function lazyLoader(lazyComponent, retryAttempts = RETRY_ATTEMPTS) {
    return new Promise((resolve, reject) => {
        lazyComponent()
            .then(resolve)
            .catch((error) => {
                console.error("Couldn't load lazy component!", error);
                setTimeout(() => {
                    if (retryAttempts === 1) {
                        reject(error);
                        return;
                    }
                    lazyLoader(lazyComponent, retryAttempts - 1).then(resolve, reject);
                }, RETRY_TIMEOUT);
            });
    });
}

//-----------------------------------------------------------------------------
// Application routed states.
//-----------------------------------------------------------------------------

const isCloud = window.serverInfo.useCentralRedirector;

const SIDEBAR_ID_DASHBOARD = isCloud ? 'console.dashboard.incidents' : 'console.dashboard.alarms';
const SIDEBAR_ID_SEARCH = 'console.search';
const SIDEBAR_ID_COMPUTERS = 'console.machines';
const SIDEBAR_ID_ALARMS = 'console.alarms';
const SIDEBAR_ID_EXECUTABLES = 'console.executables';
const SIDEBAR_ID_SCRIPTS = 'console.scripts';
const SIDEBAR_ID_INCIDENTS = 'console.incidents';
const SIDEBAR_ID_NOTIFICATIONS = 'console.notifications';
const SIDEBAR_ID_RULES = 'console.rules';
const SIDEBAR_ID_EXCLUSIONS = 'console.exclusions';
const SIDEBAR_ID_BLOCKED_HASHES = 'console.hashes';
const SIDEBAR_ID_TASKS = 'console.tasks';
const SIDEBAR_ID_EVENT_FILTERS = 'console.eventfilters';
const SIDEBAR_ID_SERVER_SETTINGS = 'console.server-settings';
const SIDEBAR_ID_USER_SETTINGS = 'console.user-settings';
const SIDEBAR_ID_SERVER_AUDIT = 'console.audit';

// React.lazy currently only supports default exports!
const Views = {
    About: lazy(() => lazyLoader(() => import('Views/about'))),
    Alerts: lazy(() => lazyLoader(() => import('Views/alerts'))),
    AggregatedEvents: lazy(() => lazyLoader(() => import('Views/aggregated-events'))),
    DetectionDetails: lazy(() => lazyLoader(() => import('Views/detections-details'))),
    DetectionDetailsPages: {
        Tabs: lazy(() => lazyLoader(() => import('Views/detections-details-pages'))),
        Occurrences: lazy(() => lazyLoader(() => import('Views/detections'))),
        Overview: lazy(() => lazyLoader(() => import('Views/detections-details'))),
    },
    Detections: lazy(() => lazyLoader(() => import('Views/detections'))),
    BlockHash: lazy(() => lazyLoader(() => import('Views/block-hash'))),
    BlockedHashes: lazy(() => lazyLoader(() => import('Views/blocked-hashes'))),
    Console: lazy(() => lazyLoader(() => import('Views/console'))),
    Errors: lazy(() => lazyLoader(() => import('Views/errors'))),
    Events: lazy(() => lazyLoader(() => import('Views/events'))),
    ExclusionWizard: lazy(() => lazyLoader(() => import('Views/exclusions-wizard'))),
    Exclusions: lazy(() => lazyLoader(() => import('Views/exclusions'))),
    Executables: lazy(() => lazyLoader(() => import('Views/executables'))),
    ExecutableInstances: lazy(() => lazyLoader(() => import('Views/executable-instances'))),
    EventsStorageFilter: lazy(() => lazyLoader(() => import('Views/events-storage-filters-wizard'))),
    Login: lazy(() => lazyLoader(() => import('Views/login'))),
    Computers: lazy(() => lazyLoader(() => import('Views/computers'))),
    Rules: lazy(() => lazyLoader(() => import('Views/rules'))),
    Scripts: lazy(() => lazyLoader(() => import('Views/scripts'))),
    ServerSettings: lazy(() => lazyLoader(() => import('Views/server-settings'))),
    UserSettings: lazy(() => lazyLoader(() => import('Views/user-settings'))),
    Incidents: lazy(() => lazyLoader(() => import('Views/incidents'))),
    IncidentsEdit: lazy(() => lazyLoader(() => import('Views/incidents-edit'))),
    IncidentsRelatedObjects: lazy(() => lazyLoader(() => import('Views/incidents-related-objects'))),
    Rule: {
        Tabs: lazy(() => lazyLoader(() => import('Views/rules-tabs'))),
        Edit: lazy(() => lazyLoader(() => import('Views/rules-edit'))),
        Targets: lazy(() => lazyLoader(() => import('Views/rules-targets'))),
        Details: lazy(() => lazyLoader(() => import('Views/rules-details'))),
    },

    Task: {
        Tabs: lazy(() => lazyLoader(() => import('Views/tasks-tabs'))),
        Details: lazy(() => lazyLoader(() => import('Views/tasks-details'))),
    },

    TaskWizard: lazy(() => lazyLoader(() => import('Views/tasks-wizard'))),
    Tasks: lazy(() => lazyLoader(() => import('Views/tasks'))),
    Audit: lazy(() => lazyLoader(() => import('Views/audit'))),
    Notifications: lazy(() => lazyLoader(() => import('Views/notifications'))),

    Incident: {
        Tabs: lazy(() => lazyLoader(() => import('Views/incidents-tabs'))),
        Timeline: lazy(() => lazyLoader(() => import('Views/incidents-timeline'))),
    },

    Process: {
        Tabs: lazy(() => lazyLoader(() => import('Views/processes-tabs'))),
        Details: lazy(() => lazyLoader(() => import('Views/processes-details'))),
        Dlls: lazy(() => lazyLoader(() => import('Views/processes-dlls'))),
        Scripts: lazy(() => lazyLoader(() => import('Views/processes-scripts'))),
    },

    Computer: {
        Tabs: lazy(() => lazyLoader(() => import('Views/computers-tabs'))),
        Details: lazy(() => lazyLoader(() => import('Views/computers-details'))),
        Terminal: lazy(() => lazyLoader(() => import('Views/computers-terminal'))),
    },

    Dashboard: {
        Tabs: lazy(() => lazyLoader(() => import('Views/dashboard-tabs'))),
        Incidents: lazy(() => lazyLoader(() => import('Views/dashboard-incidents'))),
        Detections: lazy(() => lazyLoader(() => import('Views/dashboard-detections'))),
        Computers: lazy(() => lazyLoader(() => import('Views/dashboard-computers'))),
        Executables: lazy(() => lazyLoader(() => import('Views/dashboard-executables'))),
        More: lazy(() => lazyLoader(() => import('Views/dashboard-more'))),
        ServerStatus: lazy(() => lazyLoader(() => import('Views/dashboard-server-status'))),
        EventsLoad: lazy(() => lazyLoader(() => import('Views/dashboard-events-load'))),
    },

    ModuleChanges: {
        Tabs: lazy(() => lazyLoader(() => import('Views/executables-computers-tabs'))),
        Processes: lazy(() => lazyLoader(() => import('Views/processes'))),
        Graph: lazy(() => lazyLoader(() => import('Views/incident-graph'))),
    },

    Module: {
        Tabs: lazy(() => lazyLoader(() => import('Views/executables-tabs'))),
        Details: lazy(() => lazyLoader(() => import('Views/executables-details'))),
        Statistics: lazy(() => lazyLoader(() => import('Views/executables-statistics'))),
        Computers: lazy(() => lazyLoader(() => import('Views/executables-computers'))),
    },

    Search: {
        Main: lazy(() => lazyLoader(() => import('Views/search'))),
        Results: lazy(() => lazyLoader(() => import('Views/search-results'))),
    },
};

const APP_STATES = [
    {
        name: 'login',
        url: '/login',
        pageTitle: 'LOGIN',
        component: Views.Login,
        params: {
            ott: null,
            code: null,
        },
    },
    ...(isCloud
        ? [
              {
                  name: 'redirector',
                  externalUrl: window.serverInfo.centralRedirector,
              },
          ]
        : []),
    {
        abstract: true,
        name: 'console',
        url: '/console',
        component: Views.Console,
    },
    {
        name: 'console.about',
        url: '/about',
        component: Views.About,
        pageTitle: 'ABOUT',
        helpIndex: 'index',
    },
    {
        name: 'console.rulesBy',
        url: '/rules/by/{urlFilters:ei-json}',
        component: Views.Rules,
        sidebarId: SIDEBAR_ID_RULES,
        pageTitle: 'RULES',
        helpIndex: 'rules',
        breadcrumbs: true,
    },
    {
        name: 'console.rules',
        url: '/rules',
        component: Views.Rules,
        sidebarId: SIDEBAR_ID_RULES,
        pageTitle: 'RULES',
        helpIndex: 'rules',
    },
    {
        name: 'console.exclusions',
        url: '/exclusions',
        component: Views.Exclusions,
        sidebarId: SIDEBAR_ID_EXCLUSIONS,
        pageTitle: 'EXCLUSIONS',
        helpIndex: 'exclusions',
    },
    {
        name: 'console.exclusionsBy',
        url: '/exclusions/by/{urlFilters:ei-json}',
        component: Views.Exclusions,
        sidebarId: SIDEBAR_ID_EXCLUSIONS,
        pageTitle: 'EXCLUSIONS',
        helpIndex: 'exclusions',
        breadcrumbs: true,
    },
    ...(window.serverInfo.enabledParts.eventsFilters
        ? [
              {
                  name: 'console.eventfilters',
                  url: '/event-filters',
                  component: Views.Rules,
                  sidebarId: SIDEBAR_ID_EVENT_FILTERS,
                  pageTitle: 'EVENT_FILTERS',
                  helpIndex: 'event_filters',
                  params: {
                      eventFiltersView: true,
                  },
              },
          ]
        : []),
    {
        name: 'console.tasks',
        url: '/tasks',
        component: Views.Tasks,
        sidebarId: SIDEBAR_ID_TASKS,
        pageTitle: 'TASKS',
        helpIndex: 'tasks',
    },
    {
        name: 'console.hashes',
        url: '/hashes',
        component: Views.BlockedHashes,
        sidebarId: SIDEBAR_ID_BLOCKED_HASHES,
        pageTitle: 'BLOCKED_HASHES',
        helpIndex: 'blocked_hashes',
    },
    {
        name: 'console.server-settings',
        url: '/settings',
        component: Views.ServerSettings,
        sidebarId: SIDEBAR_ID_SERVER_SETTINGS,
        pageTitle: 'SERVER_SETTINGS',
        helpTitle: 'SETTINGS',
        helpIndex: 'settings',
    },
    {
        name: 'console.user-settings',
        url: '/user',
        component: Views.UserSettings,
        sidebarId: SIDEBAR_ID_USER_SETTINGS,
        pageTitle: 'USER_SETTINGS',
        helpTitle: 'SETTINGS',
        helpIndex: 'settings',
    },
    {
        name: 'console.audit',
        url: '/audit?{urlFilters:ei-json}{filters:ei-json}',
        component: Views.Audit,
        sidebarId: SIDEBAR_ID_SERVER_AUDIT,
        pageTitle: 'AUDIT',
        helpIndex: 'audit',
    },
    {
        name: 'console.notifications',
        url: '/notifications',
        component: Views.Notifications,
        sidebarId: SIDEBAR_ID_NOTIFICATIONS,
        pageTitle: 'NOTIFICATIONS',
        helpIndex: 'questions',
    },
    {
        name: 'console.audit.row',
        url: '/{type:int}/{id:int}',
        redirectTo: (transition) => {
            return import('Bricks/action-history').then((audit) => {
                return audit.getDetailsState(transition.params().type, transition.params().id, undefined, true);
            });
        },
        sidebarId: SIDEBAR_ID_SERVER_AUDIT,
        pageTitle: 'AUDIT',
        helpIndex: 'audit',
    },
    //---------------------------------------------------------
    // DASHBOARD STATES
    //---------------------------------------------------------
    {
        abstract: true,
        name: 'console.dashboard',
        url: '/dashboard',
        component: Views.Dashboard.Tabs,
        params: {
            name: 'Dashboard',
        },
        tabs: [
            isCloud
                ? {
                      header: 'INCIDENTS',
                      icon: <IconNetworkIncidents fill="currentcolor" />,
                      route: 'console.dashboard.incidents',
                  }
                : {
                      header: 'DETECTIONS',
                      icon: <IconStatusError fill="currentcolor" />,
                      route: 'console.dashboard.alarms',
                  },
            isCloud
                ? {
                      header: 'DETECTIONS',
                      icon: <IconStatusError fill="currentcolor" />,
                      route: 'console.dashboard.alarms',
                  }
                : {
                      header: 'INCIDENTS',
                      icon: <IconNetworkIncidents fill="currentcolor" />,
                      route: 'console.dashboard.incidents',
                  },
            {
                header: 'EXECUTABLES',
                icon: <IconTerminalExecutable />,
                route: 'console.dashboard.executables',
            },
            {
                header: 'COMPUTERS',
                icon: <IconHardwareDevices fill="currentcolor" />,
                route: 'console.dashboard.computers',
            },
            { header: 'MORE', icon: <IconLetterI />, route: 'console.dashboard.more' },
            ...(window.serverInfo.enabledParts.serverStatus
                ? [
                      {
                          header: 'SERVER_STATUS',
                          icon: <IconDataChartCombo fill="currentcolor" />,
                          route: 'console.dashboard.server',
                      },
                  ]
                : []),
            ...(window.serverInfo.enabledParts.eventsLoad
                ? [
                      {
                          header: 'EVENTS_LOAD',
                          icon: <IconMediaAlarm fill="currentcolor" />,
                          route: 'console.dashboard.events',
                      },
                  ]
                : []),
        ],
    },
    {
        name: 'console.dashboard.incidents',
        url: '/incidents',
        component: Views.Dashboard.Incidents,
        sidebarId: SIDEBAR_ID_DASHBOARD,
        pageTitle: 'DASHBOARD_INCIDENTS',
        helpTitle: 'DASHBOARD',
        helpIndex: 'dashboard',
    },
    {
        name: 'console.dashboard.alarms',
        url: '/detections?{detectionNotFound:int}',
        component: Views.Dashboard.Detections,
        sidebarId: SIDEBAR_ID_DASHBOARD,
        pageTitle: 'DASHBOARD_DETECTIONS',
        helpTitle: 'DASHBOARD',
        helpIndex: 'dashboard',
    },
    {
        name: 'console.dashboard.executables',
        url: '/executables',
        component: Views.Dashboard.Executables,
        sidebarId: SIDEBAR_ID_DASHBOARD,
        pageTitle: 'DASHBOARD_EXECUTABLES',
        helpTitle: 'DASHBOARD',
        helpIndex: 'dashboard',
    },
    {
        name: 'console.dashboard.computers',
        url: '/computers',
        component: Views.Dashboard.Computers,
        sidebarId: SIDEBAR_ID_DASHBOARD,
        pageTitle: 'DASHBOARD_COMPUTERS',
        helpTitle: 'DASHBOARD',
        helpIndex: 'dashboard',
    },
    {
        name: 'console.dashboard.more',
        url: '/more',
        component: Views.Dashboard.More,
        sidebarId: SIDEBAR_ID_DASHBOARD,
        pageTitle: 'DASHBOARD_MORE',
        helpTitle: 'DASHBOARD',
        helpIndex: 'dashboard',
    },
    ...(window.serverInfo.enabledParts.serverStatus
        ? [
              {
                  name: 'console.dashboard.server',
                  url: '/server',
                  component: Views.Dashboard.ServerStatus,
                  sidebarId: SIDEBAR_ID_DASHBOARD,
                  pageTitle: 'DASHBOARD_SERVER_STATUS',
                  helpTitle: 'DASHBOARD',
                  helpIndex: 'dashboard',
              },
          ]
        : []),
    ...(window.serverInfo.enabledParts.eventsLoad
        ? [
              {
                  name: 'console.dashboard.events',
                  url: '/events',
                  component: Views.Dashboard.EventsLoad,
                  sidebarId: SIDEBAR_ID_DASHBOARD,
                  pageTitle: 'DASHBOARD_EVENTS_LOAD',
                  helpTitle: 'DASHBOARD',
                  helpIndex: 'dashboard',
              },
          ]
        : []),
    {
        name: 'console.alarmsBy',
        url: '/detections/by/{urlFilters:ei-json}',
        component: Views.Detections,
        sidebarId: SIDEBAR_ID_ALARMS,
        pageTitle: 'DETECTIONS',
        helpIndex: 'detections',
        breadcrumbs: true,
    },
    {
        name: 'console.alarms',
        url: '/detections?{filters:ei-json}',
        component: Views.Detections,
        sidebarId: SIDEBAR_ID_ALARMS,
        pageTitle: 'DETECTIONS',
        helpIndex: 'detections',
    },
    {
        name: 'console.computersBy',
        url: '/computers/by/{urlFilters:ei-json}',
        component: Views.Computers,
        sidebarId: SIDEBAR_ID_COMPUTERS,
        pageTitle: 'COMPUTERS',
        helpIndex: 'computers',
        breadcrumbs: true,
    },
    {
        name: 'console.executablesBy',
        url: '/executables/by/{urlFilters:ei-json}',
        component: Views.Executables,
        sidebarId: SIDEBAR_ID_EXECUTABLES,
        pageTitle: 'EXECUTABLES',
        helpIndex: 'executables',
        breadcrumbs: true,
    },
    {
        name: 'console.executableInstancesBy',
        url: '/executable-instances/by/{urlFilters:ei-json}',
        component: Views.ExecutableInstances,
        sidebarId: SIDEBAR_ID_EXECUTABLES,
        pageTitle: 'EXECUTABLE_INSTANCES',
        helpTitle: 'DASHBOARD',
        helpIndex: 'dashboard',
        breadcrumbs: true,
    },
    ...(window.serverInfo.enabledParts.eventsFilters
        ? [
              {
                  name: 'console.eventsStorageFilter',
                  url: '/events-storage-filter',
                  component: Views.EventsStorageFilter,
                  sidebarId: SIDEBAR_ID_EXECUTABLES,
                  pageTitle: 'NEW_EVENT_STORAGE_FILTER',
                  helpIndex: 'event_storage_filter',
                  params: {
                      eventsStorageFilterInfo: null,
                  },
              },
          ]
        : []),
    {
        name: 'console.alarm.overview',
        url: '',
        component: Views.DetectionDetailsPages.Overview,
        sidebarId: SIDEBAR_ID_ALARMS,
        pageTitle: 'DETECTION_DETAILS_OVERVIEW',
        helpIndex: 'detection_details',
        breadcrumbs: true,
    },
    {
        name: 'console.alarm.occurrences',
        url: '/occurrences',
        component: Views.DetectionDetailsPages.Occurrences,
        sidebarId: SIDEBAR_ID_ALARMS,
        pageTitle: 'DETECTION_DETAILS_OCCURRENCES',
        helpIndex: 'detection_details',
        breadcrumbs: true,
        disableProcessTree: true,
    },
    {
        name: 'console.alarm',
        url: '/detection/{alarmId:int}',
        abstract: true,
        component: Views.DetectionDetailsPages.Tabs,
        sidebarId: SIDEBAR_ID_ALARMS,
        params: {
            alarmId: { dynamic: true },
        },
        tabs: [
            {
                header: 'OVERVIEW',
                icon: <IconLetterI fill="currentcolor" />,
                route: 'console.alarm.overview',
                supportsEventFilter: true,
            },
            {
                header: 'OCCURRENCES',
                icon: <IconDataList fill="currentcolor" />,
                route: 'console.alarm.occurrences',
                onlyPrimary: true,
                supportsEventFilter: true,
            },
        ],
        breadcrumbs: true,
        processTree: true,
    },
    {
        name: 'console.exclusion',
        url: '/exclusion/{exclusionId:int}',
        component: Views.ExclusionWizard,
        sidebarId: SIDEBAR_ID_EXCLUSIONS,
        pageTitle: 'EXCLUSION_DETAILS',
        helpIndex: 'exclusion_details',
        params: {
            exclusionId: 0, // Use zero in case of new exclusion.
            exclusionInfo: null,
            exclusionRules: null,
            usingScripts: false,
        },
    },
    {
        name: 'console.scriptExclusion',
        url: '/exclusion/{exclusionId:int}',
        component: Views.ExclusionWizard,
        sidebarId: SIDEBAR_ID_EXCLUSIONS,
        pageTitle: 'SCRIPT_EXCLUSION_DETAILS',
        helpIndex: 'exclusion_details',
        params: {
            exclusionId: 0,
            exclusionInfo: null,
            exclusionRules: null,
            usingScripts: true,
        },
    },
    {
        name: 'console.rule',
        abstract: true,
        url: '/rule/{ruleId:int}',
        component: Views.Rule.Tabs,
        params: {
            ruleId: 0,
            ruleName: '',
        },
        tabs: [
            {
                header: 'DETAILS',
                icon: <IconLetterI fill="currentcolor" />,
                route: 'console.rule.details',
                disabled: false,
                supportsEventFilter: true,
            },
            {
                header: 'EDIT',
                icon: <IconEngineeringSettings fill="currentcolor" />,
                route: 'console.rule.edit',
                disabled: false,
                supportsEventFilter: true,
            },
            {
                header: 'TARGETS',
                icon: <IconHardwareDevices fill="currentcolor" />,
                route: 'console.rule.targets',
                disabled: false,
                supportsEventFilter: true,
            },
            {
                header: 'RERUN_TASKS',
                icon: <IconMediaPlay fill="currentcolor" />,
                route: 'console.rule.tasks',
                disabled: false,
                supportsEventFilter: false,
            },
            {
                header: 'EXCLUSIONS',
                icon: <IconLetterI fill="currentcolor" />,
                route: 'console.rule.exclusions',
                disabled: false,
                supportsEventFilter: false,
            },
        ],
        breadcrumbs: true,
    },
    {
        name: 'console.eventFilter',
        abstract: true,
        url: '/rule/{ruleId:int}',
        component: Views.Rule.Tabs,
        params: {
            ruleId: 0,
            ruleName: '',
        },
        tabs: [
            {
                header: 'DETAILS',
                icon: <IconLetterI fill="currentcolor" />,
                route: 'console.rule.details',
                disabled: false,
            },
            {
                header: 'EDIT',
                icon: <IconEngineeringSettings fill="currentcolor" />,
                route: 'console.rule.edit',
                disabled: false,
            },
        ],
        breadcrumbs: true,
    },
    {
        name: 'console.rule.details',
        url: '/details',
        component: Views.Rule.Details,
        sidebarId: SIDEBAR_ID_RULES,
        pageTitle: 'RULE_DETAILS',
        help: {
            index: 'rules',
            anchor: 'rule_details',
        },
    },
    {
        name: 'console.rule.edit',
        url: '/edit',
        component: Views.Rule.Edit,
        sidebarId: SIDEBAR_ID_RULES,
        pageTitle: 'RULE_EDIT',
        helpIndex: 'rule_edit',
    },
    {
        name: 'console.rule.targets',
        url: '/targets',
        component: Views.Rule.Targets,
        sidebarId: SIDEBAR_ID_RULES,
        pageTitle: 'RULE_TARGETS',
    },
    {
        name: 'console.rule.tasks',
        url: '/tasks',
        component: Views.Tasks,
        sidebarId: SIDEBAR_ID_RULES,
        pageTitle: 'RULE_RERUN_TASKS',
        helpIndex: 'rule_rerun_tasks',
    },
    {
        name: 'console.rule.exclusions',
        url: '/exclusions',
        component: Views.Exclusions,
        sidebarId: SIDEBAR_ID_RULES,
        pageTitle: 'RULE_EXCLUSIONS',
        helpIndex: 'exclusions',
    },
    {
        name: 'console.blockHash',
        url: '/blockHash',
        component: Views.BlockHash,
        sidebarId: SIDEBAR_ID_BLOCKED_HASHES,
        params: {
            sha1: [],
            alarmId: undefined,
            targetsChange: false,
            cleaned: null,
        },
        pageTitle: 'BLOCK_HASH',
        helpIndex: 'block_hash',
    },
    {
        name: 'console.executables',
        url: '/executables',
        component: Views.Executables,
        sidebarId: SIDEBAR_ID_EXECUTABLES,
        pageTitle: 'EXECUTABLES',
        helpIndex: 'executables',
    },
    {
        name: 'console.executableInstances',
        url: '/executable-instances',
        component: Views.ExecutableInstances,
        sidebarId: SIDEBAR_ID_EXECUTABLES,
        pageTitle: 'EXECUTABLE_INSTANCES',
        helpIndex: 'dashboard',
    },
    {
        abstract: true,
        name: 'console.module',
        url: '/module/{moduleId:int}',
        component: Views.Module.Tabs,
        tabs: [
            { header: 'DETAILS', icon: <IconLetterI fill="currentcolor" />, route: 'console.module.details' },
            {
                header: 'STATISTICS',
                icon: <IconHardwareStatistics fill="currentcolor" />,
                route: 'console.module.statistics',
            },
            { header: 'DETECTIONS', icon: <IconStatusError fill="currentcolor" />, route: 'console.module.alarms' },
            {
                header: 'SEEN_ON',
                icon: <IconHardwareDevices fill="currentcolor" />,
                route: 'console.module.computers',
            },
        ],
        breadcrumbs: true,
    },
    {
        name: 'console.module.details',
        url: '/details',
        component: Views.Module.Details,
        sidebarId: SIDEBAR_ID_EXECUTABLES,
        params: {
            computerId: '',
        },
        pageTitle: 'EXECUTABLE_DETAILS',
        helpIndex: 'executable_details',
    },
    {
        name: 'console.module.statistics',
        url: '/statistics',
        component: Views.Module.Statistics,
        sidebarId: SIDEBAR_ID_EXECUTABLES,
        pageTitle: 'EXECUTABLE_STATISTICS',
        helpTitle: 'EXECUTABLES',
        helpIndex: 'executables',
    },
    {
        name: 'console.module.alarms',
        url: '/detections?filters',
        component: Views.Detections,
        sidebarId: SIDEBAR_ID_EXECUTABLES,
        pageTitle: 'EXECUTABLE_DETECTIONS',
        helpTitle: 'DETECTIONS',
        helpIndex: 'detections',
    },
    {
        name: 'console.module.computers',
        url: '/computers',
        component: Views.Module.Computers,
        sidebarId: SIDEBAR_ID_EXECUTABLES,
        pageTitle: 'EXECUTABLE_SEEN_ON',
        helpIndex: 'executable_seen_on',
    },
    {
        abstract: true,
        name: 'console.moduleChanges',
        url: '/executables/{moduleId:int}/{computerId:int}',
        component: Views.ModuleChanges.Tabs,
        tabs: [
            {
                header: 'AGGREGATED_EVENTS',
                icon: <IconMediaAlarm fill="currentcolor" />,
                route: 'console.moduleChanges.modifications',
            },
            {
                header: 'PROCESSES',
                icon: <IconMediaPlay fill="currentcolor" />,
                route: 'console.moduleChanges.processes',
            },
        ],
        breadcrumbs: true,
    },
    {
        name: 'console.moduleChanges.modifications',
        url: '/modifications',
        component: Views.AggregatedEvents,
        sidebarId: SIDEBAR_ID_EXECUTABLES,
        pageTitle: 'EXECUTABLE_AGGREGATED_EVENTS',
        helpIndex: 'process_aggregated_events',
    },
    {
        name: 'console.moduleChanges.processes',
        url: '/processes',
        component: Views.ModuleChanges.Processes,
        sidebarId: SIDEBAR_ID_EXECUTABLES,
        pageTitle: 'EXECUTABLE_PROCESSES',
        helpIndex: 'executable_processes',
    },
    {
        name: 'console.process',
        abstract: true,
        url: '/process/{processId:ei-id}',
        component: Views.Process.Tabs,
        params: {
            processId: {
                dynamic: true,
            },
        },
        tabs: [
            { header: 'DETAILS', icon: <IconLetterI fill="currentcolor" />, route: 'console.process.details' },
            {
                header: 'AGGREGATED_EVENTS',
                icon: <IconMediaAlarm />,
                route: 'console.process.modifications',
            },
            {
                header: 'DETECTIONS',
                icon: <IconStatusError fill="currentcolor" />,
                route: 'console.process.alarms',
            },
            { header: 'RAW_EVENTS', icon: <IconMediaAlarm fill="currentcolor" />, route: 'console.process.events' },
            {
                header: 'LOADED_MODULES_DLLS',
                icon: <IconTerminalExecutable fill="currentcolor" />,
                route: 'console.process.dlls',
            },
            {
                header: 'SCRIPTS',
                icon: <IconTerminalScripts fill="currentcolor" />,
                route: 'console.process.scripts',
                disabledMacOs: true,
            },
        ],
        breadcrumbs: true,
        processTree: true,
    },
    {
        name: 'console.process.details',
        url: '/details',
        component: Views.Process.Details,
        sidebarId: SIDEBAR_ID_EXECUTABLES,
        pageTitle: 'PROCESS_DETAILS',
        helpIndex: 'process_details',
    },
    {
        name: 'console.process.modifications',
        url: '/modifications',
        component: Views.AggregatedEvents,
        sidebarId: SIDEBAR_ID_EXECUTABLES,
        pageTitle: 'PROCESS_AGGREGATED_EVENTS',
        helpIndex: 'process_aggregated_events',
    },
    {
        name: 'console.process.alarms',
        url: '/detections?filters',
        component: Views.Detections,
        sidebarId: SIDEBAR_ID_EXECUTABLES,
        pageTitle: 'PROCESS_DETECTIONS',
        helpIndex: 'process_detections',
    },
    {
        name: 'console.process.events',
        url: '/events?{eventId:int}',
        component: Views.Events,
        sidebarId: SIDEBAR_ID_EXECUTABLES,
        pageTitle: 'PROCESS_RAW_EVENTS',
        helpIndex: 'process_raw_events',
    },
    {
        name: 'console.process.dlls',
        url: '/dlls',
        component: Views.Process.Dlls,
        sidebarId: SIDEBAR_ID_EXECUTABLES,
        pageTitle: 'PROCESS_LOADED_MODULES',
        helpIndex: 'process_loaded_modules',
        params: {
            urlFilters: { showDlls: true },
        },
    },
    {
        name: 'console.process.scripts',
        url: '/scripts?{eventId:int}',
        component: Views.Process.Scripts,
        sidebarId: SIDEBAR_ID_EXECUTABLES,
        pageTitle: 'PROCESS_SCRIPTS',
        helpIndex: 'process_scripts',
    },
    {
        name: 'console.machines',
        url: '/machines',
        component: Views.Computers,
        sidebarId: SIDEBAR_ID_COMPUTERS,
        pageTitle: 'COMPUTERS',
        helpIndex: 'computers',
    },
    {
        abstract: true,
        name: 'console.computer',
        url: '/machines/{computerId:int}',
        component: Views.Computer.Tabs,
        tabs: [
            { header: 'DETAILS', icon: <IconLetterI fill="currentcolor" />, route: 'console.computer.details' },
            ...(window.serverInfo.enabledParts.remoteShell
                ? [
                      {
                          header: 'TERMINAL',
                          icon: <IconObjectStethoscope fill="currentcolor" />,
                          route: 'console.computer.terminal',
                          disabledMacOs: true,
                          disabledLinux: true,
                      },
                  ]
                : []),
            { header: 'ALERTS', icon: <IconStatusError fill="currentcolor" />, route: 'console.computer.alerts' },
            {
                header: 'DETECTIONS',
                icon: <IconStatusError fill="currentcolor" />,
                route: 'console.computer.alarms',
            },
            {
                header: 'EXECUTABLES',
                icon: <IconTerminalExecutable fill="currentcolor" />,
                route: 'console.computer.executables',
            },
            {
                header: 'SCRIPTS',
                icon: <IconTerminalScripts fill="currentcolor" />,
                route: 'console.computer.scripts',
            },
            { header: 'EVENTS', icon: <IconMediaAlarm fill="currentcolor" />, route: 'console.computer.events' },
        ],
        breadcrumbs: true,
    },
    {
        name: 'console.computer.details',
        url: '/details',
        component: Views.Computer.Details,
        sidebarId: SIDEBAR_ID_COMPUTERS,
        pageTitle: 'COMPUTER_DETAILS',
        helpTitle: 'COMPUTERS',
        helpIndex: 'computers',
    },
    ...(window.serverInfo.enabledParts.remoteShell
        ? [
              {
                  name: 'console.computer.terminal',
                  url: '/terminal',
                  component: Views.Computer.Terminal,
                  sidebarId: SIDEBAR_ID_COMPUTERS,
                  pageTitle: 'COMPUTER_TERMINAL',
                  helpIndex: 'terminal',
              },
          ]
        : []),
    {
        name: 'console.computer.alerts',
        url: '/alerts',
        component: Views.Alerts,
        sidebarId: SIDEBAR_ID_COMPUTERS,
        pageTitle: 'COMPUTER_ALERTS',
        help: {
            index: 'computers',
            anchor: 'alerts',
        },
    },
    {
        name: 'console.computer.alarms',
        url: '/detections?filters',
        component: Views.Detections,
        sidebarId: SIDEBAR_ID_COMPUTERS,
        pageTitle: 'COMPUTER_DETECTIONS',
        helpIndex: 'detections',
    },
    {
        name: 'console.computer.executables',
        url: '/executables',
        component: Views.Executables,
        sidebarId: SIDEBAR_ID_COMPUTERS,
        pageTitle: 'COMPUTER_EXECUTABLES',
        helpIndex: 'executables',
    },
    {
        name: 'console.computer.scripts',
        url: '/scripts',
        component: Views.Scripts,
        sidebarId: SIDEBAR_ID_COMPUTERS,
        pageTitle: 'COMPUTER_SCRIPTS',
        helpIndex: 'scripts',
    },
    {
        name: 'console.computer.events',
        url: '/events?{eventId:int}',
        component: Views.Events,
        sidebarId: SIDEBAR_ID_COMPUTERS,
        pageTitle: 'COMPUTER_EVENTS',
        helpIndex: 'process_details',
    },
    {
        abstract: true,
        name: 'console.task',
        url: '/task/{taskId:int}/{type:int}',
        component: Views.Task.Tabs,
        tabs: [
            { header: 'DETAILS', icon: <IconLetterI fill="currentcolor" />, route: 'console.task.details' },
            { header: 'DETECTIONS', icon: <IconStatusError fill="currentcolor" />, route: 'console.task.alarms' },
        ],
        breadcrumbs: true,
    },
    {
        name: 'console.task.details',
        url: '/details',
        component: Views.Task.Details,
        sidebarId: SIDEBAR_ID_TASKS,
        pageTitle: 'TASK_DETAILS',
        helpTitle: 'TASKS',
        helpIndex: 'tasks',
    },
    {
        name: 'console.task.alarms',
        url: '/detections',
        component: Views.Detections,
        sidebarId: SIDEBAR_ID_TASKS,
        pageTitle: 'TASK_DETECTIONS',
        helpTitle: 'TASKS',
        helpIndex: 'tasks',
    },
    {
        name: 'console.tasknew',
        url: '/tasknew',
        component: Views.TaskWizard,
        sidebarId: SIDEBAR_ID_TASKS,
        pageTitle: 'CREATE_RERUN_TASK',
        helpIndex: 'create_rerun_task',
        params: {
            // even if parameters is only array, "params" has to be object
            basicInfoOfRules: [],
        },
    },
    {
        name: 'console.scripts',
        url: '/scripts',
        component: Views.Scripts,
        sidebarId: SIDEBAR_ID_SCRIPTS,
        pageTitle: 'SCRIPTS',
        helpIndex: 'scripts',
    },
    {
        name: 'console.errors',
        url: '/errors',
        component: Views.Errors,
        pageTitle: 'ERRORS',
        helpIndex: 'index',
    },
    //---------------------------------------------------------
    // SEARCH STATES
    //---------------------------------------------------------
    {
        name: 'console.search',
        url: '/search',
        component: Views.Search.Main,
        sidebarId: SIDEBAR_ID_SEARCH,
        pageTitle: 'SEARCH_TASKS',
        helpTitle: 'SEARCH',
        helpIndex: 'search',
    },
    {
        name: 'console.searchresults',
        url: '/search/:searchId?{query:ei-json}?{urlFilters:ei-json}',
        component: Views.Search.Results,
        sidebarId: SIDEBAR_ID_SEARCH,
        pageTitle: 'SEARCH_RESULTS',
        helpTitle: 'SEARCH',
        helpIndex: 'search',
        breadcrumbs: true,
    },
    //---------------------------------------------------------
    // INCIDENTS
    //---------------------------------------------------------
    {
        name: 'console.incidents',
        url: '/incidents?{filters:ei-json}',
        component: Views.Incidents,
        sidebarId: SIDEBAR_ID_INCIDENTS,
        pageTitle: 'INCIDENTS',
        helpIndex: 'incidents',
    },
    {
        name: 'console.incidentsBy',
        url: '/incidents/by/{urlFilters:ei-json}',
        component: Views.Incidents,
        sidebarId: SIDEBAR_ID_INCIDENTS,
        pageTitle: 'INCIDENTS',
        helpIndex: 'incidents',
        breadcrumbs: true,
    },
    {
        name: 'console.incidentEdit',
        url: '/incident-edit/{incidentId:ei-id}',
        component: Views.IncidentsEdit,
        sidebarId: SIDEBAR_ID_INCIDENTS,
        pageTitle: 'EDIT_INCIDENT',
        helpIndex: 'edit_incident',
        params: {
            incidentId: 0,
            objectId: { dynamic: true },
            objectType: { dynamic: true },
            objectName: { dynamic: true },
            withoutOccurrences: { dynamic: true },
        },
    },
    {
        name: 'console.incidentRelatedObjects',
        url: '/incident-related-objects/{incidentId:ei-id}',
        component: Views.IncidentsRelatedObjects,
        sidebarId: SIDEBAR_ID_INCIDENTS,
        pageTitle: 'ADD_RELATED_OBJECTS',
        helpTitle: 'INCIDENTS',
        helpIndex: 'incidents',
        params: {
            incidentId: 0,
        },
    },
    {
        abstract: true,
        name: 'console.incident',
        url: '/incident/{incidentId:ei-id}',
        component: Views.Incident.Tabs,
        tabs: [
            {
                header: 'INCIDENT_GRAPH',
                icon: <IconNetworkAd fill="currentcolor" />,
                route: 'console.incident.graph',
                sidbarStartPageId: 'timeline-tab',
                allowHideSidebar: 'true',
            },
            {
                header: 'TIMELINE',
                icon: <IconLetterI fill="currentcolor" />,
                route: 'console.incident.timeline',
            },
            {
                header: 'DETECTIONS',
                icon: <IconStatusError fill="currentcolor" />,
                route: 'console.incident.detections',
            },
            {
                header: 'COMPUTERS',
                icon: <IconHardwareDevices fill="currentcolor" />,
                route: 'console.incident.computers',
            },
            {
                header: 'EXECUTABLES',
                icon: <IconTerminalExecutable fill="currentcolor" />,
                route: 'console.incident.executables',
            },
            {
                header: 'PROCESSES',
                icon: <IconMediaPlay fill="currentcolor" />,
                route: 'console.incident.processes',
            },
        ],
        breadcrumbs: true,
    },
    {
        name: 'console.incident.timeline',
        url: '/timeline',
        component: Views.Incident.Timeline,
        sidebarId: SIDEBAR_ID_INCIDENTS,
        pageTitle: 'INCIDENT_TIMELINE',
        helpTitle: 'INCIDENTS',
        helpIndex: 'incidents',
    },
    {
        name: 'console.incident.detections',
        url: '/detections',
        component: Views.Detections,
        sidebarId: SIDEBAR_ID_INCIDENTS,
        pageTitle: 'INCIDENT_DETECTIONS',
        helpTitle: 'INCIDENTS',
        helpIndex: 'incidents',
    },
    {
        name: 'console.incident.computers',
        url: '/computers',
        component: Views.Computers,
        sidebarId: SIDEBAR_ID_INCIDENTS,
        pageTitle: 'INCIDENT_COMPUTERS',
        helpTitle: 'INCIDENTS',
        helpIndex: 'incidents',
    },
    {
        name: 'console.incident.executables',
        url: '/executables',
        component: Views.Executables,
        sidebarId: SIDEBAR_ID_INCIDENTS,
        pageTitle: 'INCIDENT_EXECUTABLES',
        helpTitle: 'INCIDENTS',
        helpIndex: 'incidents',
    },
    {
        name: 'console.incident.processes',
        url: '/processes',
        component: Views.ModuleChanges.Processes,
        sidebarId: SIDEBAR_ID_INCIDENTS,
        pageTitle: 'INCIDENT_PROCESSES',
        helpTitle: 'INCIDENTS',
        helpIndex: 'incidents',
    },
    {
        name: 'console.incident.graph',
        url: '/graph',
        component: Views.ModuleChanges.Graph,
        sidebarId: SIDEBAR_ID_INCIDENTS,
        pageTitle: 'INCIDENT_GRAPH',
        helpTitle: 'INCIDENTS',
        helpIndex: 'incidents',
    },
];

export default APP_STATES;
