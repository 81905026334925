import IconStatusError from '@uilib/assets-business-icons/IconStatusError';
import IconStatusOk from '@uilib/assets-business-icons/IconStatusOk';
import IconActionFilter from '@uilib/assets-business-icons/IconActionFilter';

import React, { Component, Fragment } from 'react';
import Slider from '@uilib/business-components/Slider/Slider';
import Checkbox from '@uilib/business-components/Checkbox/Checkbox';
import Dropdown from 'uilib-wrappers/dropdown';
import Input from 'uilib-wrappers/input';
import InputNumber from 'uilib-wrappers/input-number';
import Select from 'uilib-wrappers/select';
import WebConsoleUserSelect from 'Bricks/web-console-user-select';
import classnames from 'classnames';

import i18n from 'Services/i18n';

import IconButton from 'Bricks/icon-button';
import DateRangePicker from 'Bricks/date-range-picker';
import AccessGroupFilter from './access-group-filter';
import { BLANK_OPTIONS_FILTER, OPERATOR_ICONS } from './constants';

import './index.scss';
import colors from 'eset-ui-colors';

const LABEL_WORDS_SEPARATOR = ' ';

class LocalFiltersComplex extends Component {
    constructor(props) {
        super(props);

        this.sliderCurrentValue = 0;

        const createBlankFilter = (option) => {
            return {
                id: `${this.props.id}-dropdown-blank-options-${BLANK_OPTIONS_FILTER[option].label}`,
                title: BLANK_OPTIONS_FILTER[option].label,
                callback: () => {
                    this.props.onBlankOptionClicked(
                        null,
                        BLANK_OPTIONS_FILTER[option].operator,
                        BLANK_OPTIONS_FILTER[option].value
                    );
                },
            };
        };

        this.blankOptionsMenu = [
            {
                items: [
                    {
                        id: this.props.id + '-dropdown-blank-options-enter-value',
                        title: 'ENTER_VALUE',
                        callback: () => {
                            this.props.onBlankOptionCancel(null);
                        },
                    },
                ],
            },
            {
                items: this.props.blankOptions?.map((option) => createBlankFilter(option)) || [],
            },
        ];
    }

    getLabelParts(label) {
        if (!label) {
            return '';
        }

        const WORDS = label.split(' ');
        const WORDS_PER_LINE_LIMIT = WORDS.length / 2;

        const FIRST_PART = WORDS.slice(0, WORDS_PER_LINE_LIMIT).join(LABEL_WORDS_SEPARATOR);
        const SECOND_PART = WORDS.slice(WORDS_PER_LINE_LIMIT).join(LABEL_WORDS_SEPARATOR);

        return [FIRST_PART, SECOND_PART];
    }

    getEmptyInputLabel() {
        for (let key in BLANK_OPTIONS_FILTER) {
            if (
                BLANK_OPTIONS_FILTER[key].operator === this.props.operator &&
                BLANK_OPTIONS_FILTER[key].value === this.props.value
            ) {
                return BLANK_OPTIONS_FILTER[key].label;
            }
        }
        return '';
    }

    checkboxChangeHandle = (value, event) => {
        this.props.onValueChange(event, value, true);
    };

    selectChangeHandle = (option) => {
        // We have to keep the type of select id field!
        const VALUE_AS_NUMBER = Number(option.value);
        const VALUE = isNaN(VALUE_AS_NUMBER) ? option.value : VALUE_AS_NUMBER;
        this.props.onValueChange(
            null,
            this.props.options.items.find((item) => item.id === VALUE),
            true
        );
    };

    multiSelectChangeHandle = (option) => {
        this.props.onValueChange(null, Array.isArray(option) ? option : [], true);
    };

    inputChangeHandle = (value, event) => {
        this.props.onValueChange(event, value, false);
    };

    handleSliderChange = (value, event) => {
        this.sliderCurrentValue = value;
    };

    handleSliderAfterChange = (value, event) => {
        this.props.onValueChange(event, this.sliderCurrentValue, true);
    };

    handleBlur = (value, event) => {
        this.props.onBlur(event);
    };

    render() {
        const OPERATOR_DISABLED = this.props.operators ? this.props.operators.items.length === 1 : true;
        const OPERATOR_ICON = OPERATOR_ICONS[this.props.operator];

        let rangeTicks = [];
        if (this.props.type === 'slider') {
            for (let i = this.props.minValue; i <= this.props.maxValue; ++i) {
                rangeTicks.push({ label: '|', value: i });
            }
        }

        const SELECT_OPTIONS =
            this.props.type !== 'select' && this.props.type !== 'select-time-lg'
                ? []
                : this.props.options.items.map((item) => {
                      return {
                          isDisabled: item.disabled,
                          isStatic: true,
                          label: i18n(item.name),
                          value: item.id,
                      };
                  });

        const MULTI_SELECT_OPTIONS =
            this.props.type !== 'multi-select'
                ? []
                : this.props.options.items.map((item) => {
                      return {
                          label: i18n(item.name),
                          value: item.id,
                      };
                  });
        const IS_SELECT_BLANK =
            (this.props.type === 'select' || this.props.type === 'select-time-lg') && this.props.value.id < 0;
        const i18nLabel = i18n(this.props.label);
        const i18nLabelParts = this.getLabelParts(i18nLabel);

        return (
            <form className="local-filter-form" onSubmit={this.props.onSubmit}>
                <div id={`eid-local-filter-label-${this.props.id}`} className="local-filter-label">
                    {i18nLabel.split(LABEL_WORDS_SEPARATOR).length === 1 ||
                    this.props.additionalFilterOptions?.disableLabelSplit ? (
                        i18nLabel
                    ) : (
                        <Fragment>
                            <div>{i18nLabelParts[0]}</div>
                            <div>{i18nLabelParts[1]}</div>
                        </Fragment>
                    )}
                </div>
                {this.props.blank ? (
                    <div className="local-filter-blank-label">{i18n(this.getEmptyInputLabel())}</div>
                ) : (
                    <Fragment>
                        {this.props.type !== 'checkbox' &&
                            this.props.type !== 'groupsTree' &&
                            this.props.type !== 'filterHead' &&
                            !IS_SELECT_BLANK &&
                            OPERATOR_ICON && (
                                <IconButton
                                    id={`eid-${OPERATOR_ICON}`}
                                    icon={OPERATOR_ICON}
                                    disabled={OPERATOR_DISABLED}
                                    className="local-filter-icon"
                                    onClick={this.props.onOperatorChange}
                                />
                            )}
                        {(this.props.type === 'timestamp' || this.props.type === 'dashboardTimestamp') && (
                            <div className="local-filter-time-picker local-filter-blank-label">
                                <DateRangePicker
                                    options={this.props.timestampOptions}
                                    operator={this.props.operator}
                                    value={this.props.value}
                                    onChange={this.props.onTimestampChanged}
                                />
                            </div>
                        )}
                        {this.props.type === 'checkbox' && (
                            <Checkbox
                                checked={this.props.value}
                                onChange={this.checkboxChangeHandle}
                                testDataLabel="eid-checkbox"
                            />
                        )}
                        {this.props.type === 'groupsTree' && (
                            <AccessGroupFilter
                                value={this.props.value}
                                onChange={this.props.onGroupsTreeFilterSubmitted}
                                additionalFilterOptions={this.props.additionalFilterOptions}
                            />
                        )}
                        {this.props.type === 'userSelect' && (
                            <WebConsoleUserSelect
                                allowEmpty
                                additionalFilterOptions={this.props.additionalFilterOptions}
                                className={classnames('local-filters-select', 'local-filter-users')}
                                menuPortalTarget={this.props.menuPortalTarget || document.getElementById('root')}
                                selectedUser={
                                    this.props.value
                                        ? {
                                              value: this.props.value,
                                          }
                                        : undefined
                                }
                                onChange={this.props.onAssigneeChange}
                            />
                        )}
                        {this.props.type === 'text' && (
                            <Input
                                autoFocus
                                isClearable={false}
                                onBlur={this.handleBlur}
                                value={this.props.value}
                                onChange={this.inputChangeHandle}
                            />
                        )}
                        {this.props.type === 'number' && (
                            <InputNumber
                                autoFocus
                                allowEmptyValue
                                min={this.props.minValue !== undefined ? this.props.minValue : 0}
                                max={this.props.maxValue !== undefined ? this.props.maxValue : Number.MAX_SAFE_INTEGER}
                                value={this.props.value}
                                onBlur={this.handleBlur}
                                onChange={this.inputChangeHandle}
                            />
                        )}
                        {(this.props.type === 'select' || this.props.type === 'select-time-lg') && (
                            <Select
                                menuPortalTarget={this.props.menuPortalTarget || document.getElementById('root')}
                                styles={{
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 99,
                                    }),
                                }}
                                allowEmpty={false}
                                className="local-filters-select"
                                options={SELECT_OPTIONS}
                                value={SELECT_OPTIONS.find((option) => {
                                    return option.value === this.props.value.id;
                                })}
                                onChange={this.selectChangeHandle}
                            />
                        )}
                        {this.props.type === 'multi-select' && (
                            <Select
                                menuPortalTarget={this.props.menuPortalTarget || document.getElementById('root')}
                                styles={{
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 99,
                                    }),
                                }}
                                className="local-filters-select"
                                isMulti
                                options={MULTI_SELECT_OPTIONS}
                                value={this.props.value}
                                onChange={this.multiSelectChangeHandle}
                            />
                        )}
                        {this.props.type === 'slider' && (
                            <Fragment>
                                <IconStatusError fill={colors.error} />
                                <div
                                    title={i18n(this.props.tooltipFilter(this.props.value))}
                                    className="local-filters-slider"
                                >
                                    <Slider
                                        className={`slider-${this.props.operator}-layout`}
                                        min={this.props.minValue}
                                        max={this.props.maxValue}
                                        value={this.props.value}
                                        onChange={this.handleSliderChange}
                                        onAfterChange={this.handleSliderAfterChange}
                                    />
                                    <div className="local-filters-range">
                                        {rangeTicks.map((tick, index) => (
                                            <div key={'tick-' + index} className="local-filters-tick"></div>
                                        ))}
                                    </div>
                                </div>
                                <IconStatusOk fill={colors.success} />
                            </Fragment>
                        )}
                    </Fragment>
                )}
                {this.props.blankValues && (
                    <Dropdown
                        id={this.props.id + '-dropdown-blank-options'}
                        type="custom"
                        reference={
                            <IconButton
                                id={`eid-local-filter-dropdown-${this.props.id}`}
                                icon={<IconActionFilter fill="currentcolor" />}
                                className="local-filter-icon"
                            />
                        }
                        content={this.blankOptionsMenu}
                    />
                )}
            </form>
        );
    }
}

//-----------------------------------------------------------------------------
LocalFiltersComplex.propTypes = {};

//-----------------------------------------------------------------------------
LocalFiltersComplex.defaultProps = {};

export default LocalFiltersComplex;
