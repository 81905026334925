import IconNetworkSpeed5 from '@uilib/assets-business-icons/IconNetworkSpeed5';
import IconDataList from '@uilib/assets-business-icons/IconDataList';
import IconArrowRestart from '@uilib/assets-business-icons/IconArrowRestart';
import IconArrowDownload from '@uilib/assets-business-icons/IconArrowDownload';

import { createRef, Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import toast from '@uilib/business-components/ToastContainer/Toast';
import Headline from '@uilib/business-components/Headline/Headline';
import Spacer from '@uilib/core/Spacer/Spacer';
import FlexContainer from '@uilib/core/FlexContainer/FlexContainer';
import Button from 'uilib-wrappers/button';
import ConfirmationModal from 'uilib-wrappers/confirmation-modal';
import SplitPane from 'uilib-wrappers/split-pane';
import Table from 'uilib-wrappers/table';
import Text from 'uilib-wrappers/text';

import { CancellableLoadingPanel } from 'Bricks/full-page-placeholders';
import i18n from 'Services/i18n';

import Panel from 'Bricks/Panel';
import GridCells from 'Bricks/Grid/Cells';
import { isAffectedRowsLessThanSelectedRows, updateLocalFiltersWithTags } from 'Bricks/Helpers';
import { BACKEND, withBackend } from 'Services/backend';
import { EVENT, EventNames } from 'Services/Eventing';
import { AppContext } from 'Services/AppContext';
import Filters from 'Services/Filters';
import { CommandingDropdown, CommandingContextSelection } from 'Bricks/Commanding';
import Selector from 'Bricks/selector';
import GroupFilterService from 'Services/group-filter';
import PreviewSidebar from './preview-sidebar';
import ComplexTableHeader from './header';
import ComplexTableFooter from './footer';
import { putSetting, SETTINGS } from 'Services/Settings';
import { LoadingPanel } from 'Bricks/full-page-placeholders';

/**
 * AFTER TABLE UPGRADE SHOULD BE:
 * import constants from '@uilib/business-components/_utils/constants'
 * const  { TABLE_SORTS } = constants;
 **/
const SORT_ORDER = {
    ASCENDING: 'ASCENDING',
    DESCENDING: 'DESCENDING',
};

const TABLE_COLUMN_WIDTH = {
    X_NARROW: 100,
    NARROW: 150,
    NORMAL: 200,
    WIDE: 300,
    X_WIDE: 400,
};

const TABLE_PAGE_SIZE = 100;

const SETTINGS_STORAGE_URL = 'users/settings/';
const STATUS_CHECK_TIMEOUT = 200;
const EXPORT_MAX_ROWS = 100000;

const ROW_ID_PREFIX = 'row-id:';

const MANUAL_SELECTION_CHANGE = true;

// Table component const (those are default values).
const DEFAULT_TABLE_ROW_OVERSCAN_TOP_COUNT = 10;
const DEFAULT_TABLE_ROW_HEIGHT = 36;
const DEFAULT_PREVIEW_FIRSTPANE_RATIO = 60;

// Set the emergency scroll at position of half page size.
const EMERGENCY_SCROLL_POS = (TABLE_PAGE_SIZE + DEFAULT_TABLE_ROW_OVERSCAN_TOP_COUNT) * DEFAULT_TABLE_ROW_HEIGHT;

function reorderRows(rows, oldIndex, newIndex) {
    return rows.map((row) => {
        const ROW = { ...row };
        ROW.cells = ROW.cells.map((cell) => ({ ...cell }));
        const splicedCell = ROW.cells.splice(oldIndex, 1)[0];
        ROW.cells.splice(newIndex, 0, splicedCell);

        return ROW;
    });
}

function formatTotalCount(value, isReal) {
    return isReal ? `${value}` : `${value}+`;
}

//-----------------------------------------------------------------------------
// TODO:
// Change name of this component into ComplexTable to have consistency with
// the SimpleTable component and no connection with CSS Grid!
class Grid extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);

        this.localFiltersApi = null;
        this.groupingOptionId = 0;
        this.topLevelCurrentIdx = 0;
        this.statusCheckTimer = null;
        this.selectedVisibleEntities = [];
        this.totalCount = 0;
        this.maxId = undefined;
        this.rowIdOffset = 0;
        this.pageToken = '';

        this.selectedCount = 0;

        // In order to properly handle big data we notify the grid with fake count.
        // This special value is always one PAGE_SIZE bigger than actual number of loaded elements
        // which causes that the grid scrolled to its lowest postion requests next page only.
        this.topLevelTotalCount = 0;
        this.isTopLevelTotalCountReal = true;
        // This might be improved in the future to allow scrolling to any position user wants at once
        // but to do this properly we would have to increase PAGE_SIZE much more (UI-Library team suggests: 500+)
        // and probably throw away old page of data and keep only the latest loaded one.

        this.state = {
            data: [],
            sorts: [],
            childData: [],
            columns: [],
            isDownloadModalOpen: false,
            isColumnsMenuOpen: false,
            isLoading: false,
            backendTaskInProgress: false,
            selectedItems: [],
            isAllSelected: false,
            tableSelectAllProp: null,
            selectedGroupsInfo: [],
            selectedItemsCustomId: [],
            contextMenuTarget: null,
            expectedCountValue: null,
            expectedCountMessage: null,
            selectedCount: 0,
            selectedCountMessage: '',
            gridPreviewFirstPaneRatio: DEFAULT_PREVIEW_FIRSTPANE_RATIO,

            // These option is used mainly on events view to simulate virtual view in the table.
            // The dataStartIndex variable is set to 0 if we reach first page of events or TABLE_PAGE_SIZE if not.
            // Because of this we can mislead the Table into thinking that there is only one page above the current one.
            dataStartIndex: 0,
            rowCount: 0,
            previewedRow: null,
            tableKey: 0,
        };

        if (typeof props.options.onRegisterApi === 'function') {
            props.options.onRegisterApi(this);
        }

        if (this.props.commandingConfiguration !== undefined) {
            this.commanding = new CommandingContextSelection(this.props.commandingConfiguration);
        }

        if (props.options.csvPostProcessors !== undefined) {
            this.csvPostProcessors = props.options.csvPostProcessors;
            Object.entries(this.csvPostProcessors).forEach(([key, value]) => {
                if (value.urlTemplate) {
                    value.urlTemplate = `${window.location.protocol}//${window.location.host}/console/${value.urlTemplate}`;
                }
            });
        }

        this.tableRef = createRef();
        this.toastResetViewInfoRef = createRef();
        this.commandingDropdownRef = createRef();
        this.id = `${this.props.id ? this.props.id + '-' : ''}ei-complex-table`;
        this.filterId = props.filterId || `${this.id}-filter`;
    }

    createTimeTypeCommand() {
        function switchTimeType(value) {
            return value === 'Relative' ? 'Absolute' : 'Relative';
        }

        const settings = { dateType: this.context.settings.dateType };
        const changeTimeDisplayType = () => {
            this.commanding.updateCommand(
                'TIMETYPE',
                'name',
                i18n(`DISPLAY_${settings.dateType.toUpperCase()}_TIME`, { dateType: settings.dateType })
            );
            const newDateType = { dateType: switchTimeType(settings.dateType) };
            this.context.setSettings(newDateType);
            putSetting(SETTINGS.DATE_TYPE, newDateType, this.props.componentUuid).execute();
            this.reloadDataFromSession(); // Session not changed - just timestamp columns layout.
        };

        return {
            id: 'dropdown-table-menu-time-change-download',
            title: i18n(`DISPLAY_${switchTimeType(settings.dateType).toUpperCase()}_TIME`),
            icon: <IconNetworkSpeed5 fill="currentcolor" />,
            callback: changeTimeDisplayType,
        };
    }

    localFiltersApiHandle = (localFiltersApi) => {
        this.localFiltersApi = localFiltersApi;
    };

    initializeColumns = (reset, savedColumns) => {
        const getCurrentValue = (column, fieldName, defaultValue) => {
            if (savedColumns && savedColumns[column.field]) {
                return savedColumns[column.field][fieldName];
            } else if (column[fieldName] !== undefined) {
                return column[fieldName];
            } else {
                return defaultValue;
            }
        };

        const GROUPING_NAME =
            this.groupingOptionId > 0
                ? this.props.localFiltersOptions.grouping.options[this.groupingOptionId].name + ' / '
                : '';

        const columns = this.props.options.columns.map((column, index) => {
            return {
                id: column.field,
                visible: getCurrentValue(column, 'visible', true),
                header: `${index === 0 ? GROUPING_NAME : ''}${column.name}`,
                minWidth: TABLE_COLUMN_WIDTH.X_NARROW,
                width: getCurrentValue(column, 'width', TABLE_COLUMN_WIDTH.NORMAL),
                order: getCurrentValue(column, 'order', index),
                sortDisabled: this.props.options.disableSorting || (column.sorting && column.sorting.disabled),
                subfields:
                    column.subfields /**subfields are dditional columns that will become required when the column is active, these fields will be available as additional context menu filters. ( Used to format table fields based on other entity properties and sorting)*/,
                releatedColumns:
                    column.releatedColumns /** releatedColumns are additional columns that become required when the column is active but do not affect context menu filters and sorting. ( A specific use case is a filter associated with another column defined in the filter as: additionalFilterOptions: { releatedColumn: 'columnName' ) */,
                data: column,
            };
        });
        columns.sort((column1st, column2nd) => column1st.order - column2nd.order);
        this.setState({ columns }, () => {
            if (reset) {
                this.saveSettings();
                this.reloadDataFromSession(); // Session not changed - just columns visibility.
            }
        });

        return columns;
    };

    statusCheck = () => {
        BACKEND.post('download/status', { uuid: this.state.downloadUuid, type: 'gridExport' }, this.props.componentUuid)
            .success((response) => {
                if (response.finished) {
                    this.setState({
                        backendTaskInProgress: false,
                    });
                    const date = new Date();
                    let addSufix = (number) => {
                        return number < 10 ? '0' + number : number;
                    };
                    window.location.href = `/download/${this.state.downloadUuid}/${Filters.storageExport(
                        this.props.storage
                    )}_export_${date.getFullYear()}_${addSufix(date.getMonth() + 1)}_${addSufix(
                        date.getDate()
                    )}_${addSufix(date.getHours())}_${addSufix(date.getMinutes())}_${addSufix(date.getSeconds())}${
                        this.props.options.eventsView ? '.txt' : '.csv'
                    }`;
                } else {
                    if (this.statusCheckTimer !== null) {
                        window.clearTimeout(this.statusCheckTimer);
                    }
                    this.statusCheckTimer = setTimeout(this.statusCheck, STATUS_CHECK_TIMEOUT);
                }
            })
            .always(() => {
                this.setState({
                    backendTaskInProgress: false,
                });
            })
            .execute();
    };

    handleDownloadModalClose = (event) => {
        this.setState({
            isDownloadModalOpen: false,
        });
    };

    startDownload = (uri, params) => {
        this.setState({
            backendTaskInProgress: true,
            isDownloadModalOpen: false,
        });

        BACKEND.post(uri, params, this.props.componentUuid)
            .success((response) => {
                this.setState({ downloadUuid: response.uuid });
                if (this.statusCheckTimer !== null) {
                    window.clearTimeout(this.statusCheckTimer);
                }
                this.statusCheckTimer = setTimeout(this.statusCheck, STATUS_CHECK_TIMEOUT);
            })
            .execute();
    };

    handleExportButtonClick = (event) => {
        if (this.totalCount <= EXPORT_MAX_ROWS || this.props.options.eventsView) {
            this.onExport(event);
        } else {
            this.setState({
                isDownloadModalOpen: true,
            });
        }
    };

    onExport = (event) => {
        let params = {};
        params.groupId = GroupFilterService.getId();
        if (this.localFiltersApi) {
            params.localFilters = this.localFiltersApi.get();
        }
        const sortOrders = this.getSortOrders(false);
        if (sortOrders) {
            params.sortOrders = sortOrders;
        }
        params.requiredFields = this.getQueryFields(true);
        params.frontendFields = this.getQueryFields(false);
        params.csv = true;
        params.pageSize = EXPORT_MAX_ROWS;
        if (this.groupingOptionId > 0) {
            params.grouping = {
                groupField: this.props.localFiltersOptions.grouping.options[this.groupingOptionId].field,
                groupValue: '',
            };
        }

        if (
            !this.props.options.disableSelection &&
            (this.commanding.selectedCount !== 0 || this.commanding.selectedGroupsCount !== 0)
        ) {
            params.selection = this.getSelectionData();
        }

        if (this.csvPostProcessors) {
            params.postProcessors = this.csvPostProcessors;
        }

        this.props.options.onParamsCreated?.(params);

        const uri = this.getRequestUri(0, 0); // Get plain or child elements only!
        if (this.props.options.onExport) {
            this.props.options.onExport(uri, params).then((exportInfo) => {
                this.startDownload(exportInfo.uri, exportInfo.params);
            });
        } else {
            this.startDownload(uri, params);
        }
    };

    //---------------------------------------------------------
    initializePagesFromEventsCount() {
        // Calculate number of fully loaded pages.
        const FULL_PAGES = Math.floor(this.totalCount / TABLE_PAGE_SIZE);
        // There might exist one more not fully loaded page.
        const LAST_PAGE_EVENTS = this.totalCount % TABLE_PAGE_SIZE;

        // Pages are zero-based!
        this.eventsLastPage = FULL_PAGES > 0 && LAST_PAGE_EVENTS === 0 ? FULL_PAGES - 1 : FULL_PAGES;

        // Next page should be initialized with the last one - it's always loaded on start.
        this.eventsNextPage = this.eventsLastPage;

        // It's OK for previous page to drop below 0 - this means the next page (loaded first)
        // is the one with index 0 and in this case we never load the previous page.
        this.eventsPrevPage = this.eventsNextPage - 1;
    }

    initializePagesFromEventOffset(offset) {
        // Calculate number of fully loaded pages.
        const FULL_PAGES = Math.floor(offset / TABLE_PAGE_SIZE);
        // There might exist one more not fully loaded page where that event is placed.
        const LAST_PAGE_EVENTS = offset % TABLE_PAGE_SIZE;

        // Pages are zero-based!
        const PAGE_WITH_EVENT = FULL_PAGES > 0 && LAST_PAGE_EVENTS === 0 ? FULL_PAGES - 1 : FULL_PAGES;

        this.eventsNextPage = Math.min(this.eventsLastPage, PAGE_WITH_EVENT);

        // It's OK for previous page to drop below 0 - this means the next page (loaded first)
        // is the one with index 0 and in this case we never load the previous page.
        this.eventsPrevPage = this.eventsNextPage - 1;
    }

    getEventOffsetById = (eventId) => {
        // This will return just an offset, page will be reloaded later.
        const REQUEST_BODY = {
            localFilters: this.localFiltersApi ? this.localFiltersApi.get() : undefined,
            eventTo: eventId,
        };

        return this.getEventOffset(REQUEST_BODY);
    };

    getEventOffsetByDate = (timestamp) => {
        // This will return just an offset, page will be reloaded later.
        const REQUEST_BODY = {
            localFilters: this.localFiltersApi ? this.localFiltersApi.get() : undefined,
            date: timestamp,
        };

        return this.getEventOffset(REQUEST_BODY);
    };

    getEventOffset = (requestBody) => {
        // This will return just an offset, page will be reloaded later.
        return BACKEND.post(`${this.props.options.url}/count`, requestBody, this.props.componentUuid)
            .success((response) => {
                return response.totalCount;
            })
            .execute();
    };

    returnEventByTimestamp = (timestamp, markNextEvent) => {
        let entity = null;
        for (const row of this.state.data) {
            const EVENT_TIMESTAMP = moment(row.entity.timestamp);
            if (markNextEvent) {
                // select first event with time greater or equal than given timestamp
                if (EVENT_TIMESTAMP.isAfter(timestamp)) {
                    entity = row.entity;
                    break;
                }
            } else {
                // select last event with time less than given timestamp
                if (EVENT_TIMESTAMP.isAfter(timestamp)) {
                    break;
                }
                entity = row.entity;
            }
        }
        this.findEventTimestamp = undefined;

        const eventByTimestamp =
            entity === null && this.state.data.length > 0
                ? this.state.data[markNextEvent ? this.state.data.length - 1 : 0].entity
                : entity;
        if (eventByTimestamp !== null) {
            this.props.options.onEventByTimestampFound(eventByTimestamp);
            this.jumpToEntity(eventByTimestamp);
        }
    };

    loadFirstEvents = async (forceRefresh) => {
        // Get total count of all event and calculate pages.
        await this.loadSize(forceRefresh);
        this.initializePagesFromEventsCount();

        if (this.findEventTimestamp === undefined && this.props.options.scrollToOnReload === undefined) {
            // If user clicks the reload button on events view then we have two situations:
            // * Event Id exists in the URI >> scroll to this row after event.
            // * Event Id doesn't exist in the URI >> scroll to the latest row.
            this.findEventTimestamp = { eventOffset: this.totalCount, timestamp: moment(), markNextEvent: true };
        }

        if (this.findEventTimestamp) {
            this.initializePagesFromEventOffset(this.findEventTimestamp.eventOffset);
            await this.handleLoadRowsNotification(0, 0, null, this.state.sorts, forceRefresh, undefined);
            this.returnEventByTimestamp(this.findEventTimestamp.timestamp, this.findEventTimestamp.markNextEvent);
        } else if (this.props.options.scrollToOnReload !== undefined) {
            // If we have to jump to an event then get its offset and update above pages.
            // After page with selected event is loaded scroll the Table so that event is fully visible.
            const EVENT_OFFSET = await this.getEventOffsetById(this.props.options.scrollToOnReload);
            this.initializePagesFromEventOffset(EVENT_OFFSET);
            await this.handleLoadRowsNotification(0, 0, null, this.state.sorts, forceRefresh, undefined);
            for (const [index, row] of this.state.data.entries()) {
                if (row.entity.id === this.props.options.scrollToOnReload) {
                    this.tableRef.current.jumpToRow(this.state.dataStartIndex + index);
                }
            }
        } else {
            this.handleLoadRowsNotification(0, 0, null, this.state.sorts, forceRefresh, undefined);
        }
    };

    handleReloadButtonClick = (event) => {
        this.reloadData(); // Perform full refresh.
    };

    reloadDataFromSession = () => {
        if (this.tableInitialized) {
            this.reload(false);
        }
    };

    reloadData = () => {
        if (this.tableInitialized) {
            if (this.state.isLoading) {
                this.requestCancellationUnsubscribe?.();
                this.requestCancelledUnsubscribe?.();
                /**
                 * Must be only one cancelable request per componentUuid
                 * TODO: suport multiple cancelable request per single componentUuid
                 */
                this.requestCancellationUnsubscribe = EVENT.subscribe(
                    EventNames.REQUEST_CANCELLATION_EVENT,
                    (event, { componentUuid }) => {
                        if (componentUuid === this.props.componentUuid) {
                            this.setState({ cancellationInProgress: true });
                        }
                    }
                );

                this.requestCancelledUnsubscribe = EVENT.subscribe(
                    EventNames.REQUEST_CANCELLED_EVENT,
                    (event, { componentUuid }) => {
                        if (componentUuid === this.props.componentUuid) {
                            this.reload(true);
                            this.setState({ cancellationInProgress: false });
                        }
                    }
                );

                BACKEND.cancel(this.props.componentUuid);
            } else {
                this.reload(true);
            }
        }
    };

    reload(forceRefresh) {
        if (this.reloadInProgress || this.uploadInProgress) {
            return;
        }
        this.reloadInProgress = true;

        if (typeof this.props.options.onReload === 'function') {
            this.props.options.onReload();
        }

        if (this.localFiltersApi) {
            this.groupingOptionId = this.props.localFiltersOptions.grouping?.options[
                this.localFiltersApi.getCurrentState().grouping
            ]
                ? this.localFiltersApi.getCurrentState().grouping
                : 0;
        }

        this.topLevelCurrentIdx = 0;
        this.totalCount = 0;
        this.topLevelTotalCount = 0;
        this.isTopLevelTotalCountReal = true;
        this.maxId = undefined;
        this.pageToken = '';

        if (this.props.commandingConfiguration !== undefined) {
            this.commanding.updateSelection();
        }

        this.tableRef.current.setupScroll(0, 0);

        this.setState(
            {
                isLoading: true,
                data: [],
                childData: [],
                selectedCount: 0,
                selectedCountMessage: '',
                selectedItems: [],
                isAllSelected: false,
                tableSelectAllProp: false,
                selectedGroupsInfo: [],
                selectedItemsCustomId: [],
                expectedCountValue: null,
                expectedCountMessage: null,
                dataStartIndex: 0,
                rowCount: 0,
                previewedRow: null,
                ...(this.props.options.eventsView ? {} : { tableKey: this.state.tableKey + 1 }),
            },
            () => {
                const backendRequests = [];
                if (this.props.options.eventsView) {
                    this.loadFirstEvents(forceRefresh);
                } else {
                    if (this.groupingOptionId > 0) {
                        // In case of groups we need to run extra query to get the total count.
                        const sizeRequest = this.loadSize(forceRefresh);
                        backendRequests.push(sizeRequest);
                    }
                    const dataRequest = this.handleLoadRowsNotification(
                        0,
                        TABLE_PAGE_SIZE - 1,
                        null,
                        this.state.sorts,
                        forceRefresh,
                        undefined
                    );
                    backendRequests.push(dataRequest);
                }

                const noFiltering =
                    this.localFiltersApi === null ||
                    this.localFiltersApi.getCurrentState().common.filter((localFilter) => localFilter.active).length ===
                        0;
                const noActiveTags = !(this.props.localFiltersActiveTags?.length > 0);

                if (
                    this.props.options.expectedCount !== undefined &&
                    ((noFiltering && noActiveTags) || this.props.options.expectedCount.worksWithFiltering)
                ) {
                    let localFilters =
                        this.localFiltersApi && !this.props.options.expectedCount.skipLocalFilters
                            ? this.localFiltersApi.get()
                            : undefined;
                    if (localFilters) {
                        localFilters = updateLocalFiltersWithTags(localFilters, this.props.localFiltersActiveTags);
                    }
                    const expectedCountRequest = BACKEND.post(
                        this.props.options.expectedCount.url,
                        {
                            groupId: GroupFilterService.getId(),
                            requiredFields: [this.props.options.expectedCount.field],
                            localFilters,
                            query: this.props.options.expectedCount.query,
                            objectId: this.props.options.expectedCount.objectId,
                        },
                        this.props.componentUuid
                    )
                        .withAsync()
                        .success((response) => {
                            this.setState({
                                expectedCountValue: response[this.props.options.expectedCount.field],
                            });
                        })
                        .execute();
                    backendRequests.push(expectedCountRequest);
                }
                Promise.allSettled(backendRequests).then((results) => {
                    this.updateTotalCount();

                    if (!this.isTopLevelTotalCountReal) {
                        this.loadRealTotalCount(forceRefresh);
                    } else {
                        this.checkExpectedCount();
                    }
                });
            }
        );
    }

    //-----------------------------------------------------------------------------
    // Sorting functionality.
    //-----------------------------------------------------------------------------
    getSortOrders(isGroupingActive) {
        if (this.state.sorts.length > 1) {
            // Sort the column with higher priority - this is the last clicked one.
            // var higherPriority = (sortColumns[0].sort.priority > sortColumns[1].sort.priority) ? 0 : 1;
            // var direction = sortColumns[higherPriority].sort.direction
            // scope.gridApi.grid.sortColumn(sortColumns[higherPriority], direction);
        } else if (this.state.sorts.length === 1) {
            const COLUMN_ID = this.state.sorts[0].columnId;
            const IS_ASCEND = this.state.sorts[0].order === SORT_ORDER.ASCENDING;

            for (const column of this.props.options.columns) {
                if (column.field === COLUMN_ID) {
                    const ORDER = column.sorting && column.sorting.inverse ? !IS_ASCEND : IS_ASCEND;
                    return [
                        ...(column.sortOrders ?? column.subfields ?? [COLUMN_ID]),
                        ...(this.props.options.sorting ?? []),
                    ]
                        .map((col) => ({ column: col, ascend: ORDER }))
                        .filter((field) => {
                            if (isGroupingActive) {
                                // query for groups
                                return (
                                    this.props.options.fieldsUsedInSessionForGroups === undefined ||
                                    this.props.options.fieldsUsedInSessionForGroups.includes(field.column)
                                );
                            } else {
                                // regular query
                                return (
                                    this.props.options.fieldsValidOnlyForGroups === undefined ||
                                    !this.props.options.fieldsValidOnlyForGroups.includes(field.column)
                                );
                            }
                        });
                }
            }
        }
    }
    //-----------------------------------------------------------------------------

    loadSize(forceRefresh) {
        const COUNT_FIELD = this.props.options.eventsView ? 'totalCount' : 'count';
        const requestBody = {
            groupId: GroupFilterService.getId(),
            localFilters: this.localFiltersApi ? this.localFiltersApi.get() : undefined,
            forceRefresh: !!forceRefresh,
            session: true,
            requiredFields: [COUNT_FIELD],
        };

        this.props.options.onParamsCreated?.(requestBody);

        return BACKEND.post(`${this.props.options.url}/count`, requestBody, this.props.componentUuid)
            .withAsync()
            .success((response) => {
                this.totalCount = response[COUNT_FIELD];
            })
            .execute();
    }

    loadRealTotalCount(forceRefresh) {
        const requestBody = {
            groupId: GroupFilterService.getId(),
            localFilters: this.localFiltersApi ? this.localFiltersApi.get() : undefined,
            forceRefresh: forceRefresh,
            session: true,
            sessionContextVariant: 'totalCount',
            maxId: this.maxId,
            grouping:
                this.groupingOptionId > 0
                    ? {
                          groupField: this.props.localFiltersOptions.grouping.options[this.groupingOptionId].field,
                          groupValue: '',
                      }
                    : undefined,
        };

        this.props.options.onParamsCreated?.(requestBody);

        return BACKEND.post(this.getRequestUri(undefined, 0), requestBody, this.props.componentUuid)
            .withAsync()
            .success((response) => {
                this.topLevelTotalCount = response.totalCount;
                this.isTopLevelTotalCountReal = response.isTotalCountReal;
                if (this.groupingOptionId === 0) {
                    this.totalCount = response.totalCount;
                }
                this.updateTotalCount();
                this.checkExpectedCount();
            })
            .execute();
    }

    isEventsTableScrolledTop(firstRowIndex, lastRowIndex) {
        // If Table wants data from the first page then it means it's being scrolled up.
        return firstRowIndex === 0 && lastRowIndex === TABLE_PAGE_SIZE - 1;
    }

    getRequestUri(groupIdx, firstRowIndex, lastRowIndex) {
        const URI = typeof this.props.options.url === 'function' ? this.props.options.url() : this.props.options.url;

        if (this.props.options.eventsView) {
            const CURRENT_PAGE = this.isEventsTableScrolledTop(firstRowIndex, lastRowIndex)
                ? this.eventsPrevPage
                : this.eventsNextPage;
            return `${URI}/${CURRENT_PAGE}`;
        } else if (this.groupingOptionId > 0 && groupIdx === undefined) {
            // URI used to get group elements - Groups are not exported into CSV!
            return `${URI}/groups/${Math.floor(firstRowIndex / TABLE_PAGE_SIZE)}`;
        } else if (this.groupingOptionId > 0 && groupIdx !== undefined) {
            // URI used to get child elements.
            return `${URI}/${Math.floor(firstRowIndex / TABLE_PAGE_SIZE)}`;
        } else {
            // URI used to get plain elements.
            return `${URI}/${Math.floor(firstRowIndex / TABLE_PAGE_SIZE)}`;
        }
    }

    //-----------------------------------------------------------------------------
    // Commanding helpers.
    //-----------------------------------------------------------------------------
    getSelectionData() {
        let selection = [
            {
                groupColumn: '',
                groupValue: '',
                column: 'id',
                uniqueIds: this.groupingOptionId === 0 ? this.state.selectedItemsCustomId : [],
                selectAll: this.state.isAllSelected,
            },
        ];

        if (this.groupingOptionId > 0) {
            const GROUPING_FIELD = this.props.localFiltersOptions.grouping.options[this.groupingOptionId].field;

            for (const groupId of this.state.selectedItems) {
                const SELECTED_GROUP_ISALL = this.state.selectedGroupsInfo[groupId].isAllSelected;
                const SELECTED_GROUP_ITEMS = this.state.selectedGroupsInfo[groupId].selectedItems.map(
                    this.convertChildRowIdIntoEntityId
                );

                if (!this.state.isAllSelected && !SELECTED_GROUP_ISALL && SELECTED_GROUP_ITEMS.length > 0) {
                    selection[0].uniqueIds = selection[0].uniqueIds.concat(SELECTED_GROUP_ITEMS);
                } else {
                    const groupSelection = {
                        groupColumn: GROUPING_FIELD,
                        groupValue: this.state.data[groupId].entity[GROUPING_FIELD]
                            ? this.state.data[groupId].entity[GROUPING_FIELD]
                            : 0,
                        column: 'id',
                        uniqueIds: SELECTED_GROUP_ITEMS,
                        selectAll: SELECTED_GROUP_ISALL,
                    };
                    selection.push(groupSelection);
                }
            }
        }

        return selection;
    }

    getSelectionRequestBody() {
        let bodyParameters = {};

        bodyParameters.groupId = GroupFilterService.getId();

        if (this.localFiltersApi) {
            bodyParameters.localFilters = this.localFiltersApi.get();
        }

        bodyParameters.selection = this.getSelectionData();
        bodyParameters.session = true;
        bodyParameters.forceUseSessionCache = true;

        if (this.props.options.onSelectionParamsCreated) {
            this.props.options.onSelectionParamsCreated(bodyParameters);
        }

        return bodyParameters;
    }

    getSelectedCountFromState() {
        return this.state.selectedCount;
    }

    updateVisibleSelectedEntities(isAllSelected, selectedItems, selectedGroupsInfo) {
        // In case selectAll is active take all but selected top level entities.
        if (this.groupingOptionId > 0) {
            // Grouping is enabled - state.childData contains groups with children (state.data contains rows with groups but without children).
            const selectedGroups = this.state.childData.filter(
                (group) => isAllSelected ^ selectedItems.includes(group.rowID)
            );

            for (const group of selectedGroups) {
                // Check if group exists in selectedGroupsInfo - this list MIGHT be empty if isAllSelected!!!
                // * If selectedGroupsInfo is empty then take all children of selected group.
                // * If selectedGroupsInfo is not empty then use it to filter out children of selected group.
                this.selectedVisibleEntities = group.data.map((row) => row.entity); // Take all children by default.
                const groupInfo = selectedGroupsInfo[group.rowID];
                if (groupInfo !== undefined) {
                    this.selectedVisibleEntities = this.selectedVisibleEntities.filter(
                        (entity) =>
                            groupInfo.isAllSelected ^
                            groupInfo.selectedItems.includes(this.convertEntityIdIntoChildRowId(entity.id))
                    );
                }
            }
        } else {
            // Grouping is disabled - state.data contains rows with entities.
            this.selectedVisibleEntities = this.state.data
                .filter((row) => isAllSelected ^ selectedItems.includes(row.id))
                .map((row) => row.entity);
        }
    }

    applyOnSingleSelection(action) {
        if (this.selectedVisibleEntities.length === 1) {
            action(this.selectedVisibleEntities[0]);
        }
    }

    applyOnSingleGroupSelection(action) {
        if (this.getSelectedGroupsCount(this.state.isAllSelected, this.state.selectedItems) === 1) {
            if (this.state.isAllSelected) {
                for (const group of this.state.data) {
                    if (this.state.selectedGroupsInfo[group.id] === undefined) {
                        action(this.state.data[group.id].entity);
                        return;
                    }
                }
            } else {
                action(this.state.data[this.state.selectedItems[0]].entity);
            }
        }
    }

    handleForbidden(method, URL, response, predefinedHandler) {
        return predefinedHandler();
    }

    runPredefinedHandler(method, URL, response, predefinedHandler) {
        return predefinedHandler();
    }

    // New version of update with extra body parameters.
    // For now it's temporary as it needs lots of changes in other files.
    // This will be done in the later stage of tags development.
    updateBackendWithSelectionEx(method, uri, body, onSuccess, onFailure, onStatusActions) {
        this.setState({
            backendTaskInProgress: true,
        });

        const updateRequest = BACKEND[method](
            uri,
            { ...this.getSelectionRequestBody(), ...body },
            this.props.componentUuid
        );

        updateRequest.onStatus(403, this.handleForbidden);

        onStatusActions?.forEach(({ status, callback }) => {
            updateRequest.onStatus(status, callback || this.runPredefinedHandler);
        });

        updateRequest
            .success((response) => {
                this.selectedCount = this.state.selectedCount;
                this.setState({
                    backendTaskInProgress: false,
                });
                if (typeof onSuccess === 'function') {
                    onSuccess(response, this.selectedCount);
                } else {
                    isAffectedRowsLessThanSelectedRows(response, this.selectedCount);
                    this.reloadData(); // Action might invalidate session.
                }
            })
            .failure((response) => {
                if (typeof onFailure === 'function') {
                    onFailure(response);
                }
                this.setState({
                    backendTaskInProgress: false,
                });
            });
        return updateRequest.execute();
    }

    updateBackendWithSelection(method, uri, onSuccess, onStatusActions) {
        this.setState({
            backendTaskInProgress: true,
        });
        const updateRequest = BACKEND[method](uri, this.getSelectionRequestBody(), this.props.componentUuid);

        updateRequest.onStatus(403, this.handleForbidden);
        updateRequest.onStatus(500, this.runPredefinedHandler);

        onStatusActions?.forEach(({ status, callback }) => {
            updateRequest.onStatus(status, callback || this.runPredefinedHandler);
        });

        updateRequest
            .success((response) => {
                this.selectedCount = this.state.selectedCount;
                this.setState({
                    backendTaskInProgress: false,
                });
                if (typeof onSuccess === 'function') {
                    onSuccess(response, this.selectedCount);
                } else {
                    isAffectedRowsLessThanSelectedRows(response, this.selectedCount);
                    this.reloadData(); // Action might invalidate session.
                }
            })
            .failure(() => {
                this.setState({
                    backendTaskInProgress: false,
                });
            });

        return updateRequest.execute();
    }

    convertEntityIdIntoChildRowId = (entityId, isGroup) => {
        return ROW_ID_PREFIX + entityId;
    };

    convertChildRowIdIntoEntityId = (rowId) => {
        return typeof rowId === 'string' && rowId.startsWith(ROW_ID_PREFIX)
            ? Number(rowId.substr(ROW_ID_PREFIX.length))
            : 0;
    };

    modifyData(groupIdx, entities) {
        return entities.map((entity) => {
            if (this.groupingOptionId > 0 && groupIdx === undefined) {
                // This loaded entity is used to create a group one.

                // Mark entity with special GROUP flag.
                entity.isGroup = true;
            } else if (this.groupingOptionId > 0 && groupIdx !== undefined) {
                // This loaded entity is used to create a child one.

                // Add a link to its parent - this will optimize entities processing
                // and also help us to distinguish child from plain entities.
                entity.parentId = groupIdx;
            }

            if (!entity.isGroup && typeof this.props.options.transform === 'function') {
                this.props.options.transform(entity);
            }

            if (entity.isGroup && typeof this.props.options.transformGroup === 'function') {
                this.props.options.transformGroup(this.groupingOptionId, entity);
            }

            const IS_ROW_STATUS_DISABLED =
                typeof this.props.options.isRowStatusDisabled === 'function' &&
                this.props.options.isRowStatusDisabled(entity);
            const IS_ROW_STATUS_THREAT =
                typeof this.props.options.isRowStatusThreat === 'function' &&
                this.props.options.isRowStatusThreat(entity);
            const IS_ROW_STATUS_WARNING =
                typeof this.props.options.isRowStatusWarning === 'function' &&
                this.props.options.isRowStatusWarning(entity);
            const IS_ROW_STATUS_INFO =
                typeof this.props.options.isRowStatusInfo === 'function' && this.props.options.isRowStatusInfo(entity);
            const ROW_ID =
                entity.parentId !== undefined
                    ? this.convertEntityIdIntoChildRowId(entity.id)
                    : this.topLevelCurrentIdx++;

            const newRow = {
                id: ROW_ID,
                customId: entity.id,
                isGroup: entity.isGroup,

                parentId: entity.parentId,
                totalCount: entity.isGroup ? entity.count : undefined,
                entity: entity, // HACK! Thanks to this we have access to the original backend entity.
                cells: this.state.columns.map((column) => {
                    return {
                        id: column.data.field,
                        customElement: (
                            <GridCells.CustomCell
                                id={column.data.field}
                                cellComponent={column.data.cellComponent}
                                entity={entity}
                                highlight={column.data.highlight}
                                filter={column.data.filter}
                                dateType={this.context.settings.dateType}
                                field={column.data.field}
                                refreshInterval={column.data.refreshInterval}
                                centered={column.data.centered}
                                padding={column.data.padding}
                                monospace={column.data.monospace}
                                tooltip={column.data.tooltip}
                                dynamicTooltip={column.data.dynamicTooltip}
                                tooltipFilter={column.data.tooltipFilter}
                                onShowTooltip={async (event) => this.handleShowTooltip(event, entity, column.data)}
                                onMouseDown={this.handleContextMenuHide}
                                onClick={(event, actionField) =>
                                    !this.props.disableRowClick &&
                                    this.rowClickHandle(
                                        event,
                                        actionField,
                                        () => newRow.id,
                                        entity,
                                        true,
                                        column.data,
                                        column.data.onClick
                                    )
                                }
                                onContextMenu={(event, actionField) =>
                                    !this.props.disableRowClick &&
                                    this.rowClickHandle(event, actionField, () => newRow.id, entity, false, column.data)
                                }
                                onActionFieldEnter={this.handleActionFieldEnter}
                                onActionFieldLeave={this.handleActionFieldLeave}
                            />
                        ),
                    };
                }),
                colorType: IS_ROW_STATUS_DISABLED
                    ? 'gray'
                    : IS_ROW_STATUS_THREAT
                    ? 'error'
                    : IS_ROW_STATUS_WARNING
                    ? 'warning'
                    : IS_ROW_STATUS_INFO
                    ? 'info'
                    : 'normal',
                isCheckboxDisabled:
                    typeof this.props.options.isRowCheckboxDisabled === 'function' &&
                    this.props.options.isRowCheckboxDisabled(entity),
            };
            return newRow;
        });
    }

    handleShowTooltip = (event, entity, column) => {
        if (typeof this.props.options.onShowTooltip === 'function') {
            return this.props.options.onShowTooltip(event, entity, column);
        }
    };

    handleExpandRowGroup = (groupIdx, startIndex, stopIndex) => {
        if (this.state.childData[groupIdx] !== undefined && stopIndex < this.state.childData[groupIdx].data.length) {
            const childData = this.state.childData.map((child) => ({ ...child }));
            childData[groupIdx].open = true;
            this.setState({ childData });
        } else {
            // Set ForceRefresh as true each time new children are uploaded.
            this.handleLoadRowsNotification(startIndex, stopIndex, null, this.state.sorts, true, groupIdx);
        }
    };

    handleCollapseRowGroup = (rowID, customId) => {
        if (this.state.childData[rowID] !== undefined) {
            const childData = this.state.childData.map((child) => ({ ...child }));
            childData[rowID].open = false;
            this.setState({ childData });
        }
    };

    handleLoadRowsNotification = (firstRowIndex, lastRowIndex, filters, sorts, forceRefresh, groupIdx) => {
        console.log('handleLoadRowsNotification', this.props.storage, firstRowIndex, lastRowIndex, groupIdx);

        if (isNaN(firstRowIndex) || isNaN(lastRowIndex)) {
            return; // Looks like Table sometimes sends strange indexes...
        }

        if (
            sorts !== null &&
            (this.state.sorts.length !== sorts.length ||
                (this.state.sorts.length > 0 &&
                    (this.state.sorts[0].columnId !== sorts[0].columnId ||
                        this.state.sorts[0].order !== sorts[0].order)))
        ) {
            this.setState({ sorts }, () => {
                this.saveSettings();
                this.tableRef.current.setupScroll(0, 0);
                this.reloadData(); // Session invalidated.
            });
            return;
        }

        if (this.uploadInProgress) {
            return;
        }
        this.setState({
            isLoading: true,
        });
        this.uploadInProgress = true;

        let requestBody = {};
        requestBody.pageSize = this.props.options.pageSize || TABLE_PAGE_SIZE;
        requestBody.forceRefresh = !!forceRefresh;
        requestBody.groupId = GroupFilterService.getId();
        requestBody.pageToken = this.pageToken;

        if (this.props.options.eventsView) {
            requestBody.totalCount = this.totalCount;
        }

        if (this.localFiltersApi) {
            requestBody.localFilters = this.localFiltersApi.get();
        }
        const isGroupingActive = this.groupingOptionId > 0 && groupIdx === undefined;
        requestBody.sortOrders = this.getSortOrders(isGroupingActive);
        requestBody.requiredFields = this.getGroupedQueryFields(true, groupIdx);
        requestBody.session = true;

        if (this.groupingOptionId > 0) {
            const field = this.props.localFiltersOptions.grouping.options[this.groupingOptionId].field;
            requestBody.grouping = {
                groupField: field,
                groupValue: groupIdx !== undefined ? (this.state.data[groupIdx].entity[field] || 0).toString() : '',
            };
        }

        this.props.options.onParamsCreated?.(requestBody);

        const requestStartTime = performance.now();
        return BACKEND.post(
            this.getRequestUri(groupIdx, firstRowIndex, lastRowIndex),
            requestBody,
            this.props.componentUuid
        )
            .withAsync()
            .cancellable()
            .success((response) => {
                const requestStopTime = performance.now();
                console.log(`Frontend received data in ${Math.ceil(requestStopTime - requestStartTime)}ms`);
                console.log(`JSONGenerator reported ${response.totalTime || '???'}ms`);

                if (!response.hasOwnProperty('entities')) {
                    throw new TypeError('Grid Error! >> ');
                }

                const modifiedEntities = this.modifyData(groupIdx, response.entities);

                let data = null;
                if (this.props.options.eventsView) {
                    // In order to create fake scroll-top functionality we have to mislead the table into thinking
                    // there is one page above the current one (in case of one page of data we don't have to do anything).
                    // To achieve this when we scroll the Table to the top and load new data - we notify the Table that
                    // data starts from the second page (dataStartIndex: TABLE_PAGE_SIZE).
                    // The first page is used to allow to scroll further above.

                    // Load events and attach them to the proper end of the data.
                    if (this.isEventsTableScrolledTop(firstRowIndex, lastRowIndex)) {
                        data = modifiedEntities.concat([...this.state.data]);
                        --this.eventsPrevPage;
                    } else {
                        data = this.state.data.concat(modifiedEntities);
                        ++this.eventsNextPage;
                    }

                    // Now that the new rows are attached we have to make sure their indexes are properly enumerated.
                    // This is (?) important especially when those rows are inserted at the beginning.

                    // In case we haven't reached a top - don't forget the first page is simulated one.
                    this.rowIdOffset = this.eventsPrevPage >= 0 ? TABLE_PAGE_SIZE : 0;
                    for (const [index, row] of data.entries()) {
                        row.id = this.rowIdOffset + index;
                    }
                    this.topLevelTotalCount = this.totalCount;
                    this.updateTotalCount();
                } else if (groupIdx === undefined) {
                    // Load top level group (group or plain elements).
                    data = this.state.data.concat(modifiedEntities);
                    this.topLevelTotalCount = response.totalCount;
                    this.isTopLevelTotalCountReal = response.isTotalCountReal;
                    this.maxId = response.maxId;
                    this.pageToken = response?.nextPageToken ?? '';
                    if (this.groupingOptionId === 0) {
                        this.totalCount = response.totalCount;
                    }
                    if (data.length > this.topLevelTotalCount) {
                        console.warn('Entities count is greater than total count!');
                        data.splice(this.topLevelTotalCount);
                    }
                } else {
                    // Load child elements.
                    const childData = this.state.childData.map((child) => ({ ...child }));
                    if (childData[groupIdx] === undefined) {
                        childData[groupIdx] = {
                            data: [],
                            rowID: groupIdx,
                            open: true,
                            loadedStartIndex: 0,
                        };
                    }
                    childData[groupIdx].loadedStopIndex = lastRowIndex;
                    childData[groupIdx].data = childData[groupIdx].data.concat(modifiedEntities);
                    childData[groupIdx].totalCount =
                        childData[groupIdx].data.length < this.state.data[groupIdx].totalCount
                            ? childData[groupIdx].data.length + TABLE_PAGE_SIZE
                            : this.state.data[groupIdx].totalCount;

                    this.setState({ childData });
                }

                if (data !== null) {
                    let rowCount = 0;
                    let dataStartIndex = 0;
                    // Smimulate extra vitrual pages which exists if not all data is loaded from the server.
                    if (this.props.options.eventsView) {
                        // Thanks to this, while scrolling up or down, Table will send notification to load one page only instead of many.
                        rowCount = data.length;
                        if (data.length < this.topLevelTotalCount) {
                            if (this.eventsPrevPage >= 0) {
                                rowCount += TABLE_PAGE_SIZE;
                                dataStartIndex += TABLE_PAGE_SIZE;
                            }
                            if (this.eventsNextPage <= this.eventsLastPage) {
                                rowCount += TABLE_PAGE_SIZE;
                            }
                        }
                    } else {
                        // In case we still have a data to load from the server simulate ONLY ONE extra page at the end of our list.
                        // Thanks to this, no matter how far the scroll goes down, Table will send notification to load one page only instead of many.
                        rowCount =
                            data.length < this.topLevelTotalCount
                                ? data.length + TABLE_PAGE_SIZE
                                : this.topLevelTotalCount;
                    }
                    const updateStartTime = performance.now();
                    this.setState({ data, rowCount, dataStartIndex }, () => {
                        const updateStopTime = performance.now();
                        console.log(`Frontend updated data in ${Math.ceil(updateStopTime - updateStartTime)}ms`);
                    });
                }
            })
            .always(() => {
                this.setState({ isLoading: false });

                const SELECTED_GROUPS = this.state.selectedGroupsInfo;
                const selectedItems = this.state.selectedItems;
                const selectedItemsCustomId = this.state.selectedItemsCustomId;
                const isAllSelected = this.state.isAllSelected;
                if (!isAllSelected && typeof this.props.options.preselectedItems === 'function') {
                    const preselectedItems = this.props.options.preselectedItems();
                    for (const [index, item] of this.state.data.entries()) {
                        if (preselectedItems.includes(item.entity.id)) {
                            selectedItems.push(index);
                            selectedItemsCustomId.push(item.entity.id);
                        }
                    }
                }
                this.selectionChangeHandle(
                    isAllSelected,
                    selectedItems,
                    SELECTED_GROUPS,
                    selectedItemsCustomId,
                    false,
                    true
                );

                if (this.props.options.preselectedAll) {
                    this.selectionChangeHandle(true, [], [], []);
                }

                if (this.props.options.eventsView && this.state.data.length > 0) {
                    if (
                        this.findEventTimestamp === undefined &&
                        this.isEventsTableScrolledTop(firstRowIndex, lastRowIndex)
                    ) {
                        // In case new data is loaded to the top we have to retain rows position
                        // so after new data is injected user will see same rows (they might be moved a little bit).
                        const FIXED_ROW_IDX_WHILE_SCROLLING = this.state.dataStartIndex + TABLE_PAGE_SIZE;
                        this.tableRef.current.jumpToRow(FIXED_ROW_IDX_WHILE_SCROLLING);
                    }

                    window.setTimeout(() => {
                        this.reloadInProgress = false;
                        this.uploadInProgress = false;
                        this.forceUpdate();
                        this.performHackishScrollIfNeeded(this.lastScrollTopParam, false);
                    }, 100);
                } else {
                    this.reloadInProgress = false;
                    this.uploadInProgress = false;
                }
            })
            .execute();
    };

    checkExpectedCount() {
        // Request of expected count runs parallel with entity request.
        // In order to fix this race condition we have to call this check twice:
        // 1. When expected count request ends.
        // 2. When entity request ends.
        if (this.props.options.expectedCount !== undefined && this.state.expectedCountValue !== null) {
            const invalidCount = this.props.options.expectedCount.failed(
                this.state.expectedCountValue,
                this.state.data,
                this.totalCount
            );
            const expectedCountMessage = invalidCount
                ? Filters.incompleteDbDataMessage(this.props.options.expectedCount.reason)
                : null;

            if (expectedCountMessage !== this.state.expectedCountMessage) {
                this.setState({ expectedCountMessage });
            }
        }
    }

    updateTotalCount() {
        const columns = this.state.columns.map((column, index) => {
            const newColumn = { ...column };

            if (index === 0) {
                let isGrouping = this.groupingOptionId > 0;
                const groupingName = isGrouping
                    ? `${i18n(
                          this.props.localFiltersOptions.grouping.options[this.groupingOptionId].name
                      )} (${formatTotalCount(this.topLevelTotalCount, this.isTopLevelTotalCountReal)}) / `
                    : '';

                newColumn.header = `${groupingName}${i18n(this.props.options.columns[0].name)}`;
                if (!this.props.options.hideColumnHeaderCount) {
                    newColumn.header += ` (${formatTotalCount(
                        this.totalCount,
                        isGrouping ? true : this.isTopLevelTotalCountReal
                    )})`;
                }
            } else if (index === 1 && this.props.localFiltersOptions && this.props.localFiltersOptions.grouping) {
                newColumn.visible = this.groupingOptionId > 0;
            }
            return newColumn;
        });
        this.setState({ columns });
    }

    refreshTopLevelRows(fields) {
        if (this.props.options.eventsView) {
            throw new Error('Feature not supported on Events view!');
        }
        const requestBody = {
            pageSize: this.state.data.length, // Gather all data in one request!
            requiredFields: fields,
            sortOrders: this.getSortOrders(false),
            groupId: GroupFilterService.getId(),
        };
        if (this.localFiltersApi) {
            requestBody.localFilters = this.localFiltersApi.get();
        }

        BACKEND.post(this.getRequestUri(undefined, 0), requestBody, this.props.componentUuid, BACKEND.SKIP_TOKEN_UPDATE)
            .success((response) => {
                for (const [idx, entity] of response.entities.entries()) {
                    if (this.state.data[idx].entity.id === entity.id) {
                        // Hack: update nested state fields directly - Table refreshes cells then.
                        Object.assign(this.state.data[idx].entity, { ...entity });
                    }
                }
            })
            .execute();
    }

    performHackishScrollIfNeeded(scrollTopParam, upwards) {
        const isDataAvailable = this.state.data.length > 0 && this.eventsPrevPage >= 0;
        const isSpecialScroll = this.findEventTimestamp !== undefined;
        const isOnValidCourse = upwards ? scrollTopParam < this.tableRef.current.scrollTop : true;
        const isPrevPageClose = scrollTopParam < EMERGENCY_SCROLL_POS;

        if (isDataAvailable && !isSpecialScroll && isOnValidCourse && isPrevPageClose) {
            this.tableRef.current.scrollToPosition({
                scrollLeft: -1,
                scrollTop: EMERGENCY_SCROLL_POS,
            });
            this.handleLoadRowsNotification(0, TABLE_PAGE_SIZE - 1, null, this.state.sorts);
        }
    }

    hack_switchVerticalScrollEventHandler() {
        const verticalScrollEventHandler = this.tableRef.current.handleVerticalScrollEvent.bind(this.tableRef.current);
        this.tableRef.current.handleVerticalScrollEvent = (scrollTopParam = 0) => {
            this.lastScrollTopParam = scrollTopParam;
            this.performHackishScrollIfNeeded(scrollTopParam, true);

            // If the scroll is dragged to the top sometimes there might appear infinite jump loop.
            // It looks like calling original scroll handler twice breaks this loop in most cases.
            verticalScrollEventHandler(scrollTopParam);
            verticalScrollEventHandler(scrollTopParam);
        };
    }

    handleResetViewAction = () => {
        BACKEND.cancel(this.props.componentUuid);

        setTimeout(() => {
            // reload action must wait for the async action timer abort in the macrotask queue
            this.initializeLocalFilters(this.initialTableState, true, true);
            this.initializeTable(this.initialTableState, true);
            if (this.props.localFiltersTagsOnChange !== null) {
                this.props.localFiltersTagsOnChange(null, []);
            }
            this.reloadData();
        }, 0);

        toast.dismiss(this.toastResetViewInfoRef.current);
    };

    prepareGrid() {
        if (this.props.options.eventsView) {
            // EI HACK: It appears that sometimes (not sure why / not sure when) Table
            //          doesn't notify to upload data while scrolling up in events view.
            //          To fix it we have to switch default scrolling handler with special
            //          one which always notifies to load new data whenever scroll value
            //          cross specific point - we don't care about extra notifications
            //          as upload process blocks redundant notifications.
            this.hack_switchVerticalScrollEventHandler();
        }
        this.groupFilterChangedUnsubscribe = EVENT.subscribe(EventNames.GROUP_FILTER_CHANGED_EVENT, () => {
            this.reloadData(); // Session invalidated.
        });

        this.localFiltersChangedUnsubscribe = EVENT.subscribe(EventNames.LOCAL_FILTERS_CHANGED_EVENT, (event, data) => {
            if (this.tableInitialized && data.filterId === this.filterId) {
                this.saveSettings();
                if (!data.layoutOnly) {
                    // Don't trigger reload if layout only changed i.e. inactive text filter was displayed/close.
                    this.handleReloadButtonClick(event);
                }
            }
        });

        this.AsynchronousWorkerForRequestEventUnsubscribe = EVENT.subscribe(
            EventNames.ASYNCHRONOUS_WORKER_FOR_REQUEST_EVENT,
            (event, data) => {
                if (
                    data === this.props.componentUuid &&
                    !toast.isActive(this.toastResetViewInfoRef.current) &&
                    (this.state.columns.filter((column) => column.visible === undefined || column.visible).length >
                        this.props.options.columns.filter((column) => column.visible === undefined || column.visible)
                            .length ||
                        this.localFiltersApi.isTooManyFilters())
                ) {
                    this.toastResetViewInfoRef.current = toast.info(
                        () => (
                            <FlexContainer justify="left" align="center">
                                <Spacer type="pb-1">
                                    <Text color="white">{i18n('MORE_COLUMNS_OR_MORE_FILTERS_MAKE_TABLES_SLOWER')}</Text>
                                    <Text color="white">{i18n('RESET_VIEW_IN_PRESETS_TO_SPEED_UP')}</Text>
                                </Spacer>
                                <Button
                                    id="eid-toast-button-reset"
                                    type="primary"
                                    text="RESET_VIEW"
                                    onClick={this.handleResetViewAction}
                                />
                            </FlexContainer>
                        ),
                        { autoClose: false }
                    );
                }
            }
        );

        this.loadSettings();
    }

    escapeKeyListener = (event) => {
        if (event.key === 'Escape' && (this.state.isLoading || this.state.cancellationInProgress)) {
            this.forceCancell();
        }
    };

    componentDidMount() {
        this.initializeTable();
        if (this.props.settingsInitialized !== undefined ? this.props.settingsInitialized : true) {
            this.prepareGrid();
        }
        window.addEventListener('keydown', this.escapeKeyListener);
    }

    componentDidUpdate(prevProps) {
        if (!this.tableInitialized && this.props.settingsInitialized && !prevProps.settingsInitialized) {
            this.prepareGrid();
        }
    }

    componentWillUnmount() {
        if (!this.state.cancellationInProgress && this.state.isLoading) {
            BACKEND.cancel(this.props.componentUuid);
        }
        if (this.statusCheckTimer) {
            window.clearTimeout(this.statusCheckTimer);
            this.statusCheckTimer = null;
        }
        this.toastResetViewInfoRef.current && toast.dismiss(this.toastResetViewInfoRef.current);
        this.groupFilterChangedUnsubscribe?.();
        this.localFiltersChangedUnsubscribe?.();
        this.AsynchronousWorkerForRequestEventUnsubscribe?.();
        this.requestCancelledUnsubscribe?.();
        this.requestCancellationUnsubscribe?.();
        window.removeEventListener('keydown', this.escapeKeyListener);
    }

    forceCancell = (silentCancellation) => {
        this.requestCancellationUnsubscribe?.();
        this.requestCancelledUnsubscribe?.();

        BACKEND.cancel(this.props.componentUuid);
        this.tableRef.current?.setupScroll(0, 0);

        this.setState({
            isLoading: false,
            backendTaskInProgress: false,
            cancellationInProgress: false,
            data: [],
            childData: [],
            selectedCount: 0,
            selectedCountMessage: '',
            selectedItems: [],
            isAllSelected: false,
            tableSelectAllProp: false,
            selectedGroupsInfo: [],
            selectedItemsCustomId: [],
            expectedCountValue: null,
            expectedCountMessage: null,
            dataStartIndex: 0,
            rowCount: 0,
            previewedRow: null,
            ...(this.props.options.eventsView ? {} : { tableKey: this.state.tableKey + 1 }),
        });

        !silentCancellation && toast.warning(i18n('REQUEST_WAS_CANCELLED'), { autoClose: 3000 });
    };

    //-----------------------------------------------------------------------------
    // Save state functionality.
    //-----------------------------------------------------------------------------
    getSettings(sorting, columns, filters) {
        return {
            table: {
                sorting: sorting || this.state.sorts,
                columns: (columns || this.state.columns).reduce((accumulator, column) => {
                    accumulator[column.id] = {
                        visible: column.visible,
                        width: column.width,
                        order: column.order,
                    };
                    return accumulator;
                }, {}),
            },
            gridPreviewFirstPaneRatio: this.state.gridPreviewFirstPaneRatio,
            localFilters: filters || (this.localFiltersApi ? this.localFiltersApi.getCurrentState() : undefined),
        };
    }

    saveSettings() {
        BACKEND.put(SETTINGS_STORAGE_URL + this.props.storage, this.getSettings(), this.props.componentUuid).execute();
    }

    saveGridPreviewFirstPaneRatio = (size) => {
        if (this.state.previewedRow) {
            this.setState({ gridPreviewFirstPaneRatio: size }, () => {
                this.saveSettings();
            });
        }
    };

    loadSettings() {
        // Initialize table with default state and save those settings for presets purposes.
        this.initializeTable();

        // Load user settings if exist.
        BACKEND.get(SETTINGS_STORAGE_URL + this.props.storage, this.props.componentUuid)
            .success((response) => {
                this.setState({ gridPreviewFirstPaneRatio: response.gridPreviewFirstPaneRatio });
                this.initializeLocalFilters(response); // Don't trigger reload notification here!
                this.initializeTable(response);
            })
            .always(() => {
                this.tableInitialized = true;
                this.reloadDataFromSession(); // Session not changed - first reload.
            })
            .execute();
    }

    initializeTable(settings, reset = false) {
        if (settings === undefined) {
            const sorting = this.initializeSorting();
            const columns = this.initializeColumns(reset);
            this.initialTableState = this.getSettings(sorting, columns);
        } else if (settings.table) {
            this.initializeSorting(settings.table.sorting);
            this.initializeColumns(reset, settings.table.columns);
        }
    }

    resetSplitPanels = () => {
        this.props.resetSplitPanels?.();
        this.setState({ gridPreviewFirstPaneRatio: DEFAULT_PREVIEW_FIRSTPANE_RATIO });
    };

    initializeLocalFilters(settings, notify, resetLinkFilters = false) {
        if (this.props.linkFilters && !resetLinkFilters) {
            this.localFiltersApi.setStateFromLinkFilters(this.props.linkFilters);
        } else if (settings && settings.localFilters && this.localFiltersApi && !this.props.options.resetLocalFilters) {
            this.localFiltersApi.setCurrentState(settings.localFilters, notify);
        }
    }

    initializeSorting(savedSorting) {
        let sorts = [
            {
                columnId: '',
                order: '',
            },
        ];

        if (savedSorting) {
            sorts = savedSorting;
        } else {
            this.props.options.columns
                .filter((column) => {
                    return column.sorting && column.sorting.initial;
                })
                .forEach((column) => {
                    sorts[0].columnId = column.field;

                    if (column.sorting.orders) {
                        // Complex sorting - use first ascend of orders.
                        sorts[0].order = column.sorting.orders[0].ascend ? SORT_ORDER.ASCENDING : SORT_ORDER.DESCENDING;
                    } else {
                        // Simple sorting - use ascend flag.
                        sorts[0].order = column.sorting.ascend ? SORT_ORDER.ASCENDING : SORT_ORDER.DESCENDING;
                    }
                });
        }

        this.setState({ sorts });

        return sorts;
    }

    //-----------------------------------------------------------------------------
    // Row click functionality.
    //-----------------------------------------------------------------------------
    selectSingleRow(rowId, entity) {
        // Modify selection behaviour so it's more similar to the explorer one.
        // This should work same for both buttons - in case a user clicks not selected element,
        // we have to clear existing selection and add that element so it can be chosen.

        // Mark selectAll if there is only one entity or one group with one entity.
        const IS_SELECT_ALL =
            this.state.data.length === 1 &&
            (this.state.childData.length === 0 || this.state.childData[0].data.length === 1);

        if (this.groupingOptionId > 0 && !entity.isGroup) {
            // Child row selection.
            const CHILD_SIBLINGS = this.state.childData[entity.parentId].data;

            const SELECTED_ITEMS = IS_SELECT_ALL ? [] : [entity.parentId];
            const SELECTED_ITEMS_CUSTOM_IDS = [];
            const SELECTED_GROUPS = [];
            if (!IS_SELECT_ALL) {
                SELECTED_GROUPS[entity.parentId] = {
                    isAllSelected: CHILD_SIBLINGS.length === 1,
                    selectedItems: CHILD_SIBLINGS.length === 1 ? [] : [rowId],
                };
            }

            const IS_CHILD_GROUP_SELECTED =
                (this.state.isAllSelected &&
                    (this.state.selectedItems.length === 0 || !this.state.selectedItems.includes(entity.parentId))) ||
                (!this.state.isAllSelected && this.state.selectedItems.includes(entity.parentId));
            if (!IS_CHILD_GROUP_SELECTED) {
                // Child was clicked inside of unselected group - mark that group and the child.
                this.selectionChangeHandle(
                    IS_SELECT_ALL,
                    SELECTED_ITEMS,
                    SELECTED_GROUPS,
                    SELECTED_ITEMS_CUSTOM_IDS,
                    MANUAL_SELECTION_CHANGE
                );
            } else {
                // Child was clicked inside of selected group - mark the child if not selected.
                const CHILD_GROUP_SELECT_ALL = this.state.selectedGroupsInfo[entity.parentId]
                    ? this.state.selectedGroupsInfo[entity.parentId].selectAll
                    : true;
                const CHILD_GROUP_SELECTION = this.state.selectedGroupsInfo[entity.parentId]
                    ? this.state.selectedGroupsInfo[entity.parentId].selectedItems
                    : [];

                const IS_CHILD_SELECTED =
                    (CHILD_GROUP_SELECT_ALL &&
                        (CHILD_GROUP_SELECTION.length === 0 || !CHILD_GROUP_SELECTION.includes(entity.parentId))) ||
                    (!CHILD_GROUP_SELECT_ALL && CHILD_GROUP_SELECTION.includes(entity.parentId));

                if (!IS_CHILD_SELECTED) {
                    this.selectionChangeHandle(
                        IS_SELECT_ALL,
                        SELECTED_ITEMS,
                        SELECTED_GROUPS,
                        SELECTED_ITEMS_CUSTOM_IDS,
                        MANUAL_SELECTION_CHANGE
                    );
                }
            }
        } else if (this.groupingOptionId > 0 && entity.isGroup) {
            // Group row selection.
            const SELECTED_GROUPS = [];
            if (!IS_SELECT_ALL) {
                SELECTED_GROUPS[rowId] = { isAllSelected: true, selectedItems: [] };
            }
            const SELECTED_ITEMS = IS_SELECT_ALL ? [] : [rowId];
            const SELECTED_ITEMS_CUSTOM_IDS = IS_SELECT_ALL ? [] : [entity.id];

            const IS_GROUP_SELECTED =
                (this.state.isAllSelected &&
                    (this.state.selectedItems.length === 0 || !this.state.selectedItems.includes(rowId))) ||
                (!this.state.isAllSelected &&
                    this.state.selectedItems.includes(rowId) &&
                    this.state.selectedGroupsInfo[rowId].isAllSelected);

            if (!IS_GROUP_SELECTED) {
                this.selectionChangeHandle(
                    IS_SELECT_ALL,
                    SELECTED_ITEMS,
                    SELECTED_GROUPS,
                    SELECTED_ITEMS_CUSTOM_IDS,
                    MANUAL_SELECTION_CHANGE
                );
            }
        } else {
            // Plain row selection.
            const SELECTED_GROUPS = [];
            const SELECTED_ITEMS = IS_SELECT_ALL ? [] : [rowId];
            const SELECTED_ITEMS_CUSTOM_IDS = IS_SELECT_ALL ? [] : [entity.id];

            const IS_ROW_SELECTED =
                (this.state.isAllSelected &&
                    (this.state.selectedItems.length === 0 || !this.state.selectedItems.includes(rowId))) ||
                (!this.state.isAllSelected && this.state.selectedItems.includes(rowId));

            if (!IS_ROW_SELECTED) {
                this.selectionChangeHandle(
                    IS_SELECT_ALL,
                    SELECTED_ITEMS,
                    SELECTED_GROUPS,
                    SELECTED_ITEMS_CUSTOM_IDS,
                    MANUAL_SELECTION_CHANGE
                );
            }
        }
    }

    toggleGroupsWithSelection() {
        if (this.groupingOptionId > 0) {
            if (this.state.isAllSelected) {
                // Select all enabled - toggle all but selected groups.
                for (let i = 0; i < this.state.data.length; ++i) {
                    if (!this.state.selectedItems.includes(i)) {
                        this.tableRef.current.toggleRowManually(i);
                    }
                }
            } else {
                // Select all disabled - toggle selected groups.
                for (const groupId of this.state.selectedItems) {
                    this.tableRef.current.toggleRowManually(groupId);
                }
            }
        }
    }

    formatEntityValue(value, filter) {
        if (filter !== undefined) {
            value = filter(value);
        }

        if (value === '') {
            return 'None';
        } else if (value === null) {
            return 'Unknown';
        } else if (typeof value === 'boolean') {
            return value ? 'True' : 'False';
        } else {
            return value;
        }
    }

    showContextMenu(event, entity, column) {
        if (this.props.commandingConfiguration !== undefined) {
            // We have to stop the event or this click will prevent from opening the context menu.
            column.name = i18n(column.name);
            event.preventDefault();
            this.commanding.removeFilteringOptions();

            if (this.localFiltersApi && !entity.isGroup) {
                const specialContextLayout = [];
                for (const field of column.subfields || [column.field]) {
                    const FIELD_VALUE = entity[field];
                    const filterActivationOptions = this.localFiltersApi.getActivationOptions(
                        field,
                        FIELD_VALUE,
                        entity
                    );
                    if (filterActivationOptions !== null) {
                        this.commanding.attachCommand(`COLUMN_VALUE_${field}`, false, false, {
                            name: this.formatEntityValue(FIELD_VALUE, column.filter),
                            clbk: () => {},
                            disabled: true,
                        });
                        specialContextLayout.push([`COLUMN_VALUE_${field}`]);

                        for (const [operator, action] of Object.entries(filterActivationOptions)) {
                            this.commanding.attachCommand(`SHOW_${field}_${operator}`, false, false, {
                                name: action.name,
                                icon: action.icon,
                                clbk: action.clbk,
                                disabled: this.selectedVisibleEntities.length !== 1,
                            });
                            specialContextLayout.push(`SHOW_${field}_${operator}`);
                        }
                    }
                }

                if (specialContextLayout.length > 0) {
                    this.commanding.attachFilteringOptions(
                        column.name,
                        specialContextLayout,
                        this.selectedVisibleEntities.length !== 1
                    );
                }
            }
            this.commanding.activateContext(entity.isGroup);

            const generateGetBoundingClientRect = (x = 0, y = 0) => {
                return () => ({
                    width: 0,
                    height: 0,
                    top: y,
                    right: x,
                    bottom: y,
                    left: x,
                });
            };

            const contextMenuTarget = {
                getBoundingClientRect: generateGetBoundingClientRect(event.clientX, event.clientY),
                contextElement: event.currentTarget,
            };
            this.setState({ contextMenuTarget }, this.commandingDropdownRef.current?.showCommandingDropdown?.(true));
        }
    }

    markPreviewedRow = (rowId, parentId) => {
        if (parentId !== undefined) {
            if (rowId !== this.state.previewedRow?.rowId || parentId !== this.state.previewedRow?.parentId) {
                this.setState((prevState) => {
                    const childData = prevState.childData;

                    const previewedRow = {
                        rowId,
                        parentId: parentId,
                        colorType: childData[parentId].data[rowId].colorType,
                        isFirst: rowId === 0,
                        isLast: rowId === childData[parentId].totalCount - 1,
                    };

                    if (prevState.previewedRow) {
                        childData[prevState.previewedRow.parentId].data[prevState.previewedRow.rowId].colorType =
                            prevState.previewedRow.colorType;
                    }

                    childData[parentId].data[rowId].colorType = 'info';
                    return { previewedRow, childData };
                });
            }
        } else {
            if (rowId !== this.state.previewedRow?.rowId) {
                this.setState((prevState) => {
                    const data = prevState.data;
                    const previewedRow = {
                        rowId,
                        colorType: data[rowId].colorType,
                        isFirst: rowId === 0,
                        isLast: rowId === this.totalCount - 1,
                    };

                    if (prevState.previewedRow) {
                        data[prevState.previewedRow.rowId].colorType = prevState.previewedRow.colorType;
                    }

                    data[rowId].colorType = 'info';
                    return { previewedRow, data };
                });
            }
        }
    };

    previewPreviousRow = () => {
        if (this.state.previewedRow.rowId > 0) {
            this.markPreviewedRow(this.state.previewedRow.rowId - 1, this.state.previewedRow.parentId);
        }
    };

    previewNextRow = () => {
        const parentId = this.state.previewedRow.parentId;
        if (parentId !== undefined) {
            const rowId = this.state.previewedRow.rowId;
            const dataLength = this.state.childData[parentId].data.length;

            if (rowId + 1 < dataLength) {
                this.markPreviewedRow(rowId + 1, parentId);
            } else if (dataLength < this.state.childData[parentId].totalCount) {
                this.handleLoadRowsNotification(
                    dataLength,
                    dataLength + TABLE_PAGE_SIZE - 1,
                    null,
                    this.state.sorts,
                    false,
                    parentId
                ).then(() => this.markPreviewedRow(rowId + 1, parentId));
            }
        } else {
            const rowId = this.state.previewedRow.rowId;
            const dataLength = this.state.data.length;

            if (rowId + 1 < dataLength) {
                this.markPreviewedRow(rowId + 1, parentId);
            } else if (dataLength < this.totalCount) {
                this.handleLoadRowsNotification(
                    dataLength,
                    dataLength + TABLE_PAGE_SIZE - 1,
                    null,
                    this.state.sorts
                ).then(() => this.markPreviewedRow(rowId + 1, parentId));
            }
        }
    };

    previewRowClose = () => {
        const parentId = this.state.previewedRow.parentId;
        if (parentId !== undefined) {
            this.setState((prevState) => {
                const childData = prevState.childData;
                if (prevState.previewedRow) {
                    childData[prevState.previewedRow.parentId].data[prevState.previewedRow.rowId].colorType =
                        prevState.previewedRow.colorType;
                }
                return { previewedRow: null, childData };
            });
        } else {
            this.setState((prevState) => {
                const data = prevState.data;
                if (prevState.previewedRow) {
                    data[prevState.previewedRow.rowId].colorType = prevState.previewedRow.colorType;
                }
                return { previewedRow: null, data };
            });
        }
    };

    rowClickHandle(event, actionField, rowId, entity, leftClick, column, onClick) {
        const goWithDefaultClickAction = !onClick || onClick(event, entity);
        if (goWithDefaultClickAction) {
            if (
                window.getSelection().toString() !== '' ||
                (typeof this.props.options.isRowCheckboxDisabled === 'function' &&
                    this.props.options.isRowCheckboxDisabled(entity))
            ) {
                // User selected some text - drag action shouldn't perform any click.
                return;
            }

            if (leftClick && actionField) {
                if (entity.isGroup) {
                    this.tableRef.current.toggleRowManually(rowId());
                } else {
                    if (
                        this.props.options.PreviewComponent &&
                        column.field !== this.props.options.doNotUsePreviewInColumn &&
                        !event.ctrlKey
                    ) {
                        this.markPreviewedRow(
                            entity.parentId !== undefined
                                ? this.state.childData[entity.parentId].data.findIndex(
                                      (element) => element.customId === entity.id
                                  )
                                : rowId(),
                            entity.parentId,
                            entity.id
                        );
                    } else {
                        this.props.options.onRowClicked?.(event, entity, column.field);
                    }

                    if (!this.props.options.onRowClicked || this.props.options.selectRowOnActionFieldClick) {
                        this.selectSingleRow(rowId(), entity);
                    }
                }
            } else {
                this.selectSingleRow(rowId(), entity);
                this.showContextMenu(event, entity, column);
            }
        }
    }

    handleContextMenuHide = () => {
        this.commandingDropdownRef.current?.showCommandingDropdown?.(false);
    };

    //-----------------------------------------------------------------------------
    // Selection functionality.
    //-----------------------------------------------------------------------------
    getSelectedCount = (isAllSelected, selectedItems, selectedGroupsInfo) => {
        // -----------------------------------------------------------------------------------
        // Here comes quite complicated functionality to calculate number of selected entities.
        // These can be grouped or not and so there are several different combinations:
        // -----------------------------------------------------------------------------------
        // * List of entities with some entities selected,
        // * List of entities selected by SelectAll,
        // * List of entities selected by SelectAll but with some entities deselected,
        // -----------------------------------------------------------------------------------
        // * Tree of entities with some groups selected (and so with the children),
        // * Tree of entities with some children selected (without their groups),
        // * Tree of entities with some groups and children from other groups selected,
        // * Tree of entities with all groups selected by SelectAll,
        // * Tree of entities with all groups selected by SelectAll but with some groups deselected,
        // * Tree of entities with all groups selected by SelectAll but with some groups deselected but with some of their child selected.
        // * Tree of entities with all groups selected by SelectAll but with some children deselected,
        // -----------------------------------------------------------------------------------
        if (this.groupingOptionId === 0) {
            // Grouping is disabled.
            return isAllSelected ? this.totalCount - selectedItems.length : selectedItems.length;
        }

        // Grouping is enabled.
        let selectedCount = isAllSelected ? this.totalCount : 0;
        for (const groupId of selectedItems) {
            const CHILDREN_COUNT = this.state.data[groupId].entity.count;
            const CHILDREN_SELECTED = selectedGroupsInfo[groupId].selectedItems.length;

            if (selectedGroupsInfo[groupId].isAllSelected) {
                selectedCount += isAllSelected ? -CHILDREN_SELECTED : CHILDREN_COUNT - CHILDREN_SELECTED;
            } else {
                selectedCount += isAllSelected ? -CHILDREN_COUNT + CHILDREN_SELECTED : CHILDREN_SELECTED;
            }
        }
        return selectedCount;
    };

    getSelectedGroupsCount = (isAllSelected, selectedItems, selectedGroupsInfo) => {
        // Calculate number of selected top level entities.
        const SELECTION = isAllSelected ? this.topLevelTotalCount - selectedItems.length : selectedItems.length;

        return this.groupingOptionId > 0 ? SELECTION : 0;
    };

    selectionChangeHandle = (
        isAllSelected,
        selectedItems,
        selectedGroupsInfo,
        selectedItemsCustomId,
        manualSelectionChange,
        onLoadRowsSelectionChange
    ) => {
        if (manualSelectionChange) {
            this.skipNextSelectionChange = true;
        } else if (this.skipNextSelectionChange) {
            // After manual selection change Table component triggers same selection notification as manual one.
            this.skipNextSelectionChange = false;
            return;
        }

        this.setState({
            selectedItems,
            isAllSelected,
            tableSelectAllProp: isAllSelected !== this.state.isAllSelected ? isAllSelected : null,
            selectedGroupsInfo,
            selectedItemsCustomId,
        });

        this.updateVisibleSelectedEntities(isAllSelected, selectedItems, selectedGroupsInfo);

        if (this.props.options && this.props.options.onRowSelectionChanged) {
            this.props.options.onRowSelectionChanged(
                this.selectedVisibleEntities,
                isAllSelected,
                onLoadRowsSelectionChange,
                this.groupingOptionId > 0 ? undefined : this.state.data
            );
        }

        if (this.props.commandingConfiguration !== undefined) {
            const SELECTED_GROUPS_COUNT = this.getSelectedGroupsCount(isAllSelected, selectedItems, selectedGroupsInfo);
            const SELECTED_COUNT = this.getSelectedCount(isAllSelected, selectedItems, selectedGroupsInfo);
            this.commanding.updateSelection(
                SELECTED_GROUPS_COUNT,
                SELECTED_COUNT,
                this.selectedVisibleEntities,
                isAllSelected
            );

            this.setState({
                selectedCount: SELECTED_COUNT,
                selectedCountMessage:
                    SELECTED_COUNT > 0 ? `Selected Items: ${SELECTED_COUNT} / ${this.totalCount}` : '',
            });
        }
    };

    handleSelectedItemsCount = (selectedItemsCount, allSelectableItems) => {
        return this.state.selectedCountMessage;
    };

    getQueryFields = (addRequiredFields) => {
        // Grouping is not active.
        const REQUIRED_FIELDS = (addRequiredFields && this.props.options.requiredFields
            ? this.props.options.requiredFields
            : []
        ).concat(
            this.state.columns
                .filter((column) => column.visible)
                .flatMap((column) => {
                    return [column.id].concat(column.subfields || []).concat(column.releatedColumns || []);
                })
        );

        // Remove duplicates and fields valid only for groups
        let resultFields = Array.from(new Set(REQUIRED_FIELDS));
        if (this.props.options.fieldsValidOnlyForGroups !== undefined)
            resultFields = resultFields.filter((field) => !this.props.options.fieldsValidOnlyForGroups.includes(field));
        return resultFields;
    };

    getGroupedQueryFields = (addRequiredFields, groupIdx) => {
        if (this.groupingOptionId > 0 && groupIdx === undefined && this.props.options.requiredGroupFields) {
            // Grouping is active and groups are requested.
            return this.props.options.requiredGroupFields[this.groupingOptionId];
        } else {
            return this.getQueryFields(addRequiredFields);
        }
    };

    findEventByTimestamp = async (timestamp, markNextEvent) => {
        if (!this.props.options.eventsView) {
            return; // Specific feature of events view only!
        }

        let eventOffset = await this.getEventOffsetByDate(timestamp);
        if (eventOffset === undefined) {
            return; // Don't load events if a user clicked a button before results were fetched.
        }
        eventOffset += markNextEvent ? 1 : 0;

        if (
            eventOffset > this.eventsPrevPage * TABLE_PAGE_SIZE &&
            eventOffset <= this.eventsNextPage * TABLE_PAGE_SIZE
        ) {
            // Event is present in the data - reload is not needed.
            this.returnEventByTimestamp(timestamp, markNextEvent);
        } else {
            // Event is not present in the data - we have to reload the table with proper page.
            this.findEventTimestamp = { eventOffset, timestamp, markNextEvent };
            this.reloadDataFromSession(); // Session not changed - just a reload of different page.
        }
    };

    jumpToEntity(entity) {
        for (const [index, row] of this.state.data.entries()) {
            if (row.entity.id === entity.id) {
                this.tableRef.current.jumpToRow(this.state.dataStartIndex + index);
            }
        }
    }

    handleColumnsReorder = (oldIndex, newIndex) => {
        if (newIndex === 0 || oldIndex === 0) {
            return; // First column is fixed!
        }
        if (this.groupingOptionId !== 0 && (newIndex === 1 || oldIndex === 1)) {
            return; // Grouping enabled - Second column (count) is also fixed!
        }

        const columns = this.state.columns.map((column) => ({ ...column }));
        const splicedColumn = columns.splice(oldIndex, 1)[0];
        columns.splice(newIndex, 0, splicedColumn);
        for (const [index, item] of columns.entries()) {
            item.order = index;
        }

        const data = reorderRows([...this.state.data], oldIndex, newIndex);
        const childData = this.state.childData.map((child) => ({
            ...child,
            data: reorderRows(child.data, oldIndex, newIndex),
        }));

        // Hide columns before data switch to properly refresh their cells.
        // Table is refreshed properly even with this batched columns update.
        this.setState({ columns: this.state.columns.map((column) => ({ ...column, visible: false })) });
        this.setState({ columns, data, childData }, () => {
            this.saveSettings();
        });
    };

    handleColumnSizeChange = (columnID, size) => {
        const columns = this.state.columns.map((column) => ({ ...column }));
        for (const column of columns) {
            if (column.id === columnID) {
                column.width = size;
            }
        }
        this.setState({ columns }, () => {
            this.saveSettings();
        });
    };

    handleSelectColumns = () => {
        this.columnsMenu = this.props.options.disableColumnsMenu
            ? null
            : this.state.columns
                  .filter((column, index) => {
                      // Hide the first column and on views with the grouping hide the second one (groups count).
                      return this.props.localFiltersOptions && this.props.localFiltersOptions.grouping
                          ? index !== 0 && index !== 1
                          : index !== 0;
                  })
                  .map((column) => {
                      return {
                          id: column.id,
                          label: column.header,
                          checked: column.visible,
                      };
                  })
                  .sort((column1st, column2nd) => column1st.label.localeCompare(column2nd.label));

        this.setState({ isColumnsMenuOpen: true });
    };

    handleResetColumns = () => {
        this.tableRef.current.setupScroll(0, 0);
        this.initializeColumns(true);
    };

    handleColumnsMenuChange = (event, selection) => {
        let columnAdded = false;
        let columnRemoved = false;
        const columns = this.state.columns.map((column, index) => {
            const newColumn = { ...column };
            if (!newColumn.visible && selection[column.id]) {
                columnAdded = true;
                EVENT.publish(EventNames.TABLE_COLUMNS_VISIBILITY_CHANGED_EVENT, { id: column.id, visible: true });
            }

            if (index > 0 && newColumn.visible && !selection[column.id]) {
                columnRemoved = true;
                EVENT.publish(EventNames.TABLE_COLUMNS_VISIBILITY_CHANGED_EVENT, { id: column.id, visible: false });
            }

            if (selection[column.id] !== undefined) {
                newColumn.visible = selection[column.id];
            }
            return newColumn;
        });

        this.setState({ columns }, () => {
            this.saveSettings();
            if (columnAdded || columnRemoved) {
                this.tableRef.current.setupScroll(0, 0);
            }
            if (columnAdded) {
                this.reloadDataFromSession(); // Session not changed - just a column was added.
            }
        });
    };

    handleColumnsMenuClose = (event) => {
        this.setState({
            isColumnsMenuOpen: false,
        });
    };

    render() {
        return (
            <Panel
                testDataLabel={this.props.testDataLabel}
                headerType={this.props.options.disableHeader ? 'none' : 'small'}
                header={
                    <ComplexTableHeader
                        title={
                            typeof this.props.title !== 'string' ? (
                                this.props.title
                            ) : (
                                <Spacer type="mr-2">
                                    <Headline className="ui-fix-page-headline-title" bottomMargin={false} type="h2">
                                        {i18n(this.props.title)}
                                    </Headline>
                                </Spacer>
                            )
                        }
                        isLoading={!this.tableInitialized || this.state.backendTaskInProgress}
                        onExportClick={this.handleExportButtonClick}
                        onReloadClick={this.handleReloadButtonClick}
                        localFiltersOptions={this.props.localFiltersOptions}
                        tableApi={this}
                        localFiltersTags={this.props.localFiltersTags}
                        localFiltersActiveTags={this.props.localFiltersActiveTags}
                        onTagsChange={this.props.localFiltersTagsOnChange}
                        storage={this.props.storage}
                        initialTableState={this.initialTableState}
                        onRegisterApi={this.localFiltersApiHandle}
                        showWarningEventsStoringDisabled={this.props.showWarningEventsStoringDisabled}
                        idParameter={this.props.idParameter}
                        menuPortalTarget={this.props.menuPortalTarget}
                    />
                }
                footer={
                    <ComplexTableFooter
                        isLoading={!this.tableInitialized || this.state.backendTaskInProgress}
                        toolbarVisible={this.props.commandingConfiguration !== undefined}
                        commanding={this.commanding}
                        groupingOptionId={this.groupingOptionId}
                        expectedCountMessage={this.state.expectedCountMessage}
                        CustomCommandingComponent={this.props.CustomCommandingComponent}
                    />
                }
            >
                <SplitPane
                    removeSplitPane={!this.props.options.PreviewComponent}
                    expandWidthAfterRemove
                    forceFirstPaneRatio={!this.state.previewedRow}
                    firstPaneRatio={!this.state.previewedRow ? 100 : this.state.gridPreviewFirstPaneRatio}
                    savePaneRatio={this.saveGridPreviewFirstPaneRatio}
                    parentSize={'100%'}
                >
                    <Table
                        key={this.state.tableKey}
                        id={this.id}
                        testDataLabel="ei-complex-table"
                        ref={this.tableRef}
                        height={this.props.halfHeight ? '50vh' : null}
                        noHeaderTopBorder
                        columnsAutoSize
                        columns={this.state.columns}
                        onColumnsReorder={this.handleColumnsReorder}
                        onColumnSizeChange={this.handleColumnSizeChange}
                        headerContextMenu={
                            this.props.options.disableColumnsMenu && !this.props.options.enableExportTxtButton
                                ? null
                                : [
                                      {
                                          header: 'TABLE_OPTIONS',
                                          items: [
                                              ...(this.props.options.disableColumnsMenu
                                                  ? []
                                                  : [
                                                        {
                                                            id: 'dropdown-table-menu-item-select',
                                                            title: 'SELECT_COLUMNS',
                                                            icon: <IconDataList fill="currentcolor" />,
                                                            callback: this.handleSelectColumns,
                                                        },
                                                        {
                                                            id: 'dropdown-table-menu-item-reset',
                                                            title: 'RESET_COLUMNS',
                                                            icon: <IconArrowRestart fill="currentcolor" />,
                                                            callback: this.handleResetColumns,
                                                        },
                                                    ]),
                                              ...(this.props.options.disableExport
                                                  ? []
                                                  : [
                                                        {
                                                            id: 'dropdown-table-menu-item-download',
                                                            title: this.props.options.enableExportTxtButton
                                                                ? 'EXPORT_TABLE_AS_TXT'
                                                                : 'EXPORT_TABLE_AS_CSV',
                                                            icon: <IconArrowDownload fill="currentcolor" />,
                                                            callback: this.handleExportButtonClick,
                                                        },
                                                    ]),
                                              ...(this.props.options.disableColumnsMenu
                                                  ? []
                                                  : [this.createTimeTypeCommand()]),
                                          ],
                                      },
                                  ]
                        }
                        data={this.state.data}
                        dataStartIndex={this.state.dataStartIndex}
                        childData={this.state.childData}
                        rowCount={this.state.rowCount}
                        maxRowLoad={TABLE_PAGE_SIZE}
                        loadRowsNotification={this.handleLoadRowsNotification}
                        isLoading={this.state.isLoading || !this.tableInitialized || this.state.backendTaskInProgress}
                        // Remove handlers in case of no grouping to remove empty space used on groups icons.
                        onExpandRowGroup={this.groupingOptionId === 0 ? undefined : this.handleExpandRowGroup}
                        onCollapseRowGroup={this.groupingOptionId === 0 ? undefined : this.handleCollapseRowGroup}
                        selectionChangeHandle={this.props.options.disableSelection ? null : this.selectionChangeHandle}
                        selectAllDisplay={
                            this.props.options.disableSelectAll !== undefined
                                ? !this.props.options.disableSelectAll
                                : true
                        }
                        selectAllItems={this.props.options.disableSelection ? null : this.state.tableSelectAllProp}
                        selectedItems={this.props.options.disableSelection ? null : this.state.selectedItems}
                        selectedGroupsInfo={this.props.options.disableSelection ? null : this.state.selectedGroupsInfo}
                        selectedItemsCount={{
                            showCount: this.state.selectedCountMessage !== '',
                            callback: this.handleSelectedItemsCount,
                        }}
                        sorts={this.props.options.disableSorting ? null : this.state.sorts}
                        emptyFrozenColumn={-34} //UI Library hack: Remove empty space reserved for in cell context Menu button
                        contextMenuProvider={{
                            /*
                             * UI Library hack:
                             * We have context menu CommandingDropdown but this is not native Table contextMenu so hasContextMenu always need to be false
                             * We still need onContextMenuClose because this is the only place where it is possible to close contextMenu on scroll
                             * onContextMenuClose cannot change the state of this component - otherwise column stretching will end with an error due to the component rerender,
                             * unfortunately, I can't do anything about it, so changing the contextmenu opening state must be inside the component CommandingDropdown and executed by ref
                             */
                            hasContextMenu: () => false,
                            getContextMenu: () => undefined,
                            onContextMenuClose: this.handleContextMenuHide,
                        }}
                        singleSelectionMode={this.props.singleSelectionMode}
                    />
                    <Fragment>
                        {this.props.options.PreviewComponent && this.state.previewedRow && (
                            <PreviewSidebar
                                data={
                                    this.state.previewedRow.parentId !== undefined
                                        ? this.state.childData[this.state.previewedRow.parentId].data[
                                              this.state.previewedRow.rowId
                                          ]
                                        : this.state.data[this.state.previewedRow.rowId]
                                }
                                previewedRow={this.state.previewedRow}
                                onInfoClick={this.props.options.onRowClicked}
                                previewPrevious={this.previewPreviousRow}
                                previewNext={this.previewNextRow}
                                previewClose={this.previewRowClose}
                                reload={this.reloadData}
                                HeaderComponent={this.state.columns[0]?.data.cellComponent}
                                PreviewComponent={this.props.options.PreviewComponent}
                            />
                        )}
                    </Fragment>
                </SplitPane>
                <CommandingDropdown
                    ref={this.commandingDropdownRef}
                    commanding={this.commanding}
                    target={this.state.contextMenuTarget}
                    groupingOptionId={this.groupingOptionId}
                    onHide={this.handleContextMenuHide}
                />
                <ConfirmationModal
                    show={this.state.isDownloadModalOpen}
                    type="warning"
                    message="ROWS_LIMIT_EXCEEDED"
                    text={i18n('DO_YOU_WANT_TO_DOWNLOAD_FIRST_N_ROWS', { count: EXPORT_MAX_ROWS })}
                    onDispose={this.handleDownloadModalClose}
                    buttons={[
                        <Button id="eid-download-button-ok" type="primary" text="OK" onClick={this.onExport} />,
                        <Button
                            id="eid-download-button-cancel"
                            type="secondary"
                            text="CANCEL"
                            onClick={this.handleDownloadModalClose}
                        />,
                    ]}
                />
                <Selector
                    quickSearch
                    isOpen={this.state.isColumnsMenuOpen}
                    title="SELECT_COLUMNS"
                    options={this.columnsMenu}
                    onChange={this.handleColumnsMenuChange}
                    onClose={this.handleColumnsMenuClose}
                />
                <CancellableLoadingPanel
                    show={this.state.cancellationInProgress || this.state.isLoading}
                    onlyLabel={!this.state.cancellationInProgress && this.state.isLoading}
                    onCancel={this.forceCancell}
                />
                <LoadingPanel show={this.state.backendTaskInProgress} />
            </Panel>
        );
    }
}

//-----------------------------------------------------------------------------
Grid.propTypes = {
    storage: PropTypes.string.isRequired,
    options: PropTypes.shape({
        resetLocalFilters: PropTypes.bool,
        // requiredFields
        //requiredGroupFields
        //columns
        eventsView: PropTypes.bool,
        onRowClicked: PropTypes.func,
        transform: PropTypes.func,
        transformGroup: PropTypes.func,
        isRowStatusThreat: PropTypes.func,
        isRowStatusWarning: PropTypes.func,
        onRegisterApi: PropTypes.func,
        onParamsCreated: PropTypes.func,
        onReload: PropTypes.func,
        url: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    }),
    localFiltersOptions: PropTypes.object,
    commandingConfiguration: PropTypes.object,
    linkFilters: PropTypes.object,
};

//-----------------------------------------------------------------------------
Grid.defaultProps = {
    options: {
        resetLocalFilters: false,
        // requiredFields
        requiredGroupFields: undefined,
        //columns
        eventsView: false,
        onRowClicked: undefined,
        transform: undefined,
        transformGroup: undefined,
        isRowStatusThreat: undefined,
        isRowStatusWarning: undefined,
        onRegisterApi: undefined,
        onParamsCreated: undefined,
        onReload: undefined,
        onExport: undefined,
        url: '',
    },
    localFiltersOptions: undefined,
    commandingConfiguration: undefined,
    linkFilters: undefined,
};

export { TABLE_COLUMN_WIDTH, TABLE_PAGE_SIZE };

export default withBackend(Grid);
