import React, { useState, useEffect, Fragment, useMemo } from 'react';

import { EVENT, EventNames } from 'Services/Eventing';
import { BACKEND, withBackend } from 'Services/backend';
import RemediationModalIncident from 'Bricks/remediation-modal-incident';
import { CommandingContextBasic, CommandingToolbar } from 'Bricks/Commanding';
import { createSimpleSelectionBody } from 'Bricks/Helpers';
import { CommentEditor } from 'Bricks/comments';
import CommentDeleteWindow from 'Bricks/comments/commentDeleteWindow';
import createIncidentesCommanding from 'Views/incidents/commanding';
import { TagsEditModalWithBackend } from 'Bricks/tags-edit-modal';
import AccessGroupModal from 'Bricks/access-group-modal';
import { updateAccessGroupSubmenuOnSelectionChange } from 'Bricks/Helpers';
import StatusAssigneeModal from 'Views/incidents/status-assignee-modal';
import ReopenModal from 'Views/incidents/reopen-modal';

function IncidentCommandingToolbar(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [incidentDetails, setIncidentDetails] = useState(null);
    const [isCommentEditorOpen, setIsCommentEditorOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [activeComment, setActiveComment] = useState({ commentId: 0, commentValue: '' });
    const [showRemediationModal, setShowRemediationModal] = useState(false);
    const [isTagsEditOpen, setIsTagsEditOpen] = useState(false);
    const [isAccessGroupModalOpen, setIsAccessGroupModalOpen] = useState(false);
    const [isStatusAssigneeModalOpen, setIsStatusAssigneeModalOpen] = useState(false);
    const [isReopenModalOpen, setIsReopenModalOpen] = useState(false);
    const [isStatusAssigneeModalUniversal, setIsStatusAssigneeModalUniversal] = useState(false);

    const incidentCommanding = useMemo(
        () =>
            createIncidentesCommanding({
                tagsEditOpen: tagsEditOpen,
                getIncidentInfo: getIncidentInfo,
                accessGroupSubmenuClbk: accessGroupSubmenuClbk,
                mode: props.mode,
                reload: reload,
                componentUuid: props.componentUuid,
                onStatusAssigneeModalOpen: handleStatusAssigneeModalOpen,
                onReopenModalOpen: handleReopenModalOpen,
                onAddComment: handleAddComment,
                onRemediate: onRemediate,
            }),
        [incidentDetails]
    );
    const [commanding, setCommanding] = useState(createCommanding(props.commanding));

    function createCommanding(commanding) {
        return new CommandingContextBasic({
            ...commanding,
            commands: {
                ...incidentCommanding.commands,
                ...commanding?.commands,
            },
            toolbar: {
                ...(commanding?.toolbar || []),
                right: [...incidentCommanding.toolbar.right, ...(commanding?.toolbar?.right || [])],
                left: [...incidentCommanding?.toolbar.left, ...(commanding?.toolbar?.left || [])],
            },
        });
    }

    function handleStatusAssigneeModalClose(isStatusAssigneeModalUniversal) {
        setIsStatusAssigneeModalUniversal(isStatusAssigneeModalUniversal);
        setIsStatusAssigneeModalOpen(false);
    }

    function handleStatusAssigneeModalOpen() {
        setIsStatusAssigneeModalOpen(true);
    }

    function handleReopenModalClose() {
        setIsReopenModalOpen(false);
    }

    function handleReopenModalOpen() {
        setIsReopenModalOpen(true);
    }

    function tagsEditOpen() {
        setIsTagsEditOpen(true);
    }

    function handleTagsEditClose() {
        setIsTagsEditOpen(false);
    }

    function accessGroupSubmenuClbk() {
        setIsAccessGroupModalOpen(true);
    }

    function handleAccessGroupModalClose() {
        setIsAccessGroupModalOpen(false);
    }

    function handleAccessGroupModal(selectedGroupId) {
        const requestBody = createSimpleSelectionBody([props.incidentId]);
        requestBody['accessGroupId'] = selectedGroupId;

        return BACKEND.post('incidents/access_group', requestBody, props.componentUuid).success(reload).execute();
    }

    function getIncidentInfo() {
        return {
            incidentId: props.incidentId,
        };
    }

    function reload(notNotify) {
        setIsLoading(true);
        BACKEND.post(`incident/${props.incidentId}`, {}, props.componentUuid)
            .onStatus(404, () => {
                // hmmm not found
            })
            .success((incident) => {
                setIncidentDetails(incident);
            })
            .always(() => {
                !notNotify && EVENT.publish(EventNames.INCIDENT_MODIFIED_EVENT);
                props.reload?.();
                setIsLoading(false);
            })
            .execute();
    }

    useEffect(() => {
        props.setReloadLeftWing?.(reload);
        reload(true);
    }, []);

    function isRemoved() {
        return incidentDetails?.status === window.serverInfo.constants.incidentStatus.deleted;
    }

    function handleAddComment() {
        setActiveComment({ commentId: 0, commentValue: '' });
        setIsCommentEditorOpen(true);
    }

    function handleDeleteWindowClose() {
        setIsDeleteOpen(false);
    }

    function closeCommentEditor() {
        setIsCommentEditorOpen(false);
    }

    function onRemediate() {
        setShowRemediationModal(true);
    }

    function handleRemediationModaleClose() {
        setShowRemediationModal(false);
    }

    async function changeStatus(status, { reason, assignee, comment }) {
        let success = false;
        await BACKEND.post(
            reason !== undefined ? `incidents/status/${status}/reason/${reason}` : `incidents/status/${status}`,
            {
                ...createSimpleSelectionBody([props.incidentId]),
                ...(assignee !== undefined && { assignee }),
                ...(comment && { comment }),
            },
            props.componentUuid
        )
            .success((response) => {
                success = {
                    skippedExecutionOnSomeObjects: response.skippedExecutionOnSomeObjects,
                };
            })
            .failure(() => {
                success = false;
            })
            .execute();

        return success;
    }

    useEffect(() => {
        const commanding = createCommanding(props.commanding);

        if (!window.serverInfo.useCentralRedirector) {
            incidentDetails &&
                updateAccessGroupSubmenuOnSelectionChange(commanding, [
                    {
                        accessGroupId: incidentDetails?.accessGroup,
                        accessGroupPath: incidentDetails?.accessGroupPath,
                        isInternal: incidentDetails?.isInternal,
                    },
                ]);
        }

        const componentCommandingKeys = Object.keys(props.commanding?.commands || {});
        if (props.isLoading) {
            commanding.disable(...componentCommandingKeys);
        } else {
            commanding.enable(...componentCommandingKeys);
        }

        const incidentCommandingKeys = Object.keys(incidentCommanding?.commands || {});
        if (isRemoved()) {
            commanding.disable(...incidentCommandingKeys, ...componentCommandingKeys);
            commanding.setTooltip('CANNOT_EXECUTE_ACTION_ON_REMOVED_INCIDENT', ...incidentCommandingKeys);
        } else if (isLoading) {
            commanding.disable(...incidentCommandingKeys);
        } else {
            for (const command in incidentCommanding?.commands) {
                if (
                    !incidentDetails ||
                    (typeof commanding.commands[command]?.shouldBlock === 'function' &&
                        commanding.commands[command].shouldBlock([incidentDetails], true, true))
                ) {
                    commanding.disable(command);
                } else {
                    commanding.enable(command);
                }

                if (incidentDetails && typeof commanding.commands[command]?.shouldHide === 'function') {
                    commanding[
                        commanding.commands[command].shouldHide([incidentDetails], true, true) ? 'hide' : 'show'
                    ](command);
                }

                if (typeof commanding.commands[command]?.getTooltip === 'function') {
                    commanding.commands[command].tooltip = commanding.commands[command].getTooltip([incidentDetails]);
                }
            }
        }

        setCommanding(commanding);
    }, [props.commanding, props.isLoading, isLoading, incidentDetails]);

    const entities = useMemo(() => [incidentDetails], [incidentDetails]);

    return (
        <Fragment>
            <CommandingToolbar commanding={commanding} groupingOptionId={props.groupingOptionId} />
            {props.incidentId !== undefined && (
                <Fragment>
                    <CommentEditor
                        isOpen={isCommentEditorOpen}
                        activeComment={activeComment}
                        objectId={props.incidentId}
                        objectType={window.serverInfo.constants.dbObject.incident}
                        onClose={closeCommentEditor}
                        onChange={reload}
                    />
                    <CommentDeleteWindow
                        isOpen={isDeleteOpen}
                        commentId={activeComment.commentId}
                        objectId={props.incidentId}
                        objectType={window.serverInfo.constants.dbObject.incident}
                        onClose={handleDeleteWindowClose}
                        onChange={reload}
                    />
                    <RemediationModalIncident
                        show={showRemediationModal}
                        close={handleRemediationModaleClose}
                        incidentId={props.incidentId}
                    />
                    <TagsEditModalWithBackend
                        tagsObjectId={props.incidentId}
                        tagsObjectType={window.serverInfo.constants.dbObject.incident}
                        open={isTagsEditOpen}
                        onClose={handleTagsEditClose}
                        onChange={reload}
                    />
                    <AccessGroupModal
                        show={isAccessGroupModalOpen}
                        onChange={handleAccessGroupModal}
                        close={handleAccessGroupModalClose}
                        preselectedGroupId={incidentDetails?.accessGroup}
                    />
                    <StatusAssigneeModal
                        show={isStatusAssigneeModalOpen}
                        isStatusAssigneeModalUniversal={isStatusAssigneeModalUniversal}
                        close={handleStatusAssigneeModalClose}
                        entities={entities}
                        changeStatus={changeStatus}
                        reload={reload}
                    />
                    <ReopenModal
                        show={isReopenModalOpen}
                        close={handleReopenModalClose}
                        entities={entities}
                        changeStatus={changeStatus}
                        reload={reload}
                    />
                </Fragment>
            )}
        </Fragment>
    );
}

export default withBackend(IncidentCommandingToolbar);
